import axios from "axios";

/**
 * Function to retrieve a list of thermographies for a specific park.
 * @param {string} parkId - The ID of the park for which to retrieve thermographies.
 * @returns {Promise} - Axios promise object representing the GET request.
 */
export const retrieveThermographies = (parkId) => {
  return axios.get(`/places/parks/${parkId}/thermographies`);
};

/**
 * Function to create a new thermography for a specific park.
 * @param {string} parkId - The ID of the park in which to create the thermography.
 * @param {string} timestamp - The timestamp of the thermography.
 * @param {File} thermographyImagePark - The thermography image file.
 * @param {File} errorProbabilityReport - The error probability report file.
 * @param {File} specializedErrorReport - The specialized error report file.
 * @returns {Promise} - Axios promise object representing the POST request.
 */
export const createThermography = (
  parkId,
  timestamp,
  thermographyImagePark,
  errorPropabilityReport,
  specializedErrorReport
) => {
  const formData = new FormData();
  formData.append("park_id", parkId);
  formData.append("timestamp", timestamp);
  formData.append("thermography_image_park", thermographyImagePark);
  formData.append("error_probability_report", errorPropabilityReport);
  formData.append("specialized_error_report", specializedErrorReport);

  return axios.post(`/places/parks/${parkId}/thermographies`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

/**
 * Function to retrieve information about a specific thermography in a park.
 * @param {string} parkId - The ID of the park containing the thermography.
 * @param {string} thermographyId - The ID of the thermography to retrieve.
 * @returns {Promise} - Axios promise object representing the GET request.
 */
export const retrieveThermography = (parkId, thermographyId) => {
  return axios.get(`/places/parks/${parkId}/thermographies/${thermographyId}`);
};

/**
 * Function to delete a specific thermography in a park.
 * @param {string} parkId - The ID of the park containing the thermography.
 * @param {string} thermographyId - The ID of the thermography to delete.
 * @returns {Promise} - Axios promise object representing the DELETE request.
 */
export const deleteThermography = (parkId, thermographyId) => {
  return axios.delete(
    `/places/parks/${parkId}/thermographies/${thermographyId}`
  );
};

/**
 * Function to retrieve a list of panels associated with a specific thermography in a park.
 * @param {string} parkId - The ID of the park containing the thermography.
 * @param {string} thermographyId - The ID of the thermography containing the panels.
 * @returns {Promise} - Axios promise object representing the GET request.
 */
export const retrieveThermographyPanels = (parkId, thermographyId) => {
  return axios.get(
    `/places/parks/${parkId}/thermographies/${thermographyId}/panels`
  );
};

/**
 * Function to retrieve information about a specific panel associated with a thermography in a park.
 * @param {string} parkId - The ID of the park containing the thermography.
 * @param {string} thermographyId - The ID of the thermography containing the panel.
 * @param {string} panelId - The ID of the panel to retrieve.
 * @returns {Promise} - Axios promise object representing the GET request.
 */
export const retrieveThermographyPanel = (parkId, thermographyId, panelId) => {
  return axios.get(
    `/places/parks/${parkId}/thermographies/${thermographyId}/panels/${parkId}`
  );
};

/**
 * Function to retrieve meteorological data associated with a specific thermography in a park.
 * @param {string} parkId - The ID of the park containing the thermography.
 * @param {string} thermographyId - The ID of the thermography for which to retrieve meteorological data.
 * @returns {Promise} - Axios promise object representing the GET request.
 */
export const retrieveMeteorologicalData = (parkId, thermographyId) => {
  return axios.get(
    `/places/parks/${parkId}/thermographies/${thermographyId}/meteorological`
  );
};

/**
 * Retrieve telemetry data.
 *
 * @param {int} placeId - The unique identifier of the park.
 * @param {int} thermographyId - The unique identifier of the thermography.
 * @returns {Promise} A Promise that resolves when the invasion request is sent successfully.
 * @throws {Error} If the invasion request fails, an error is thrown.
 */
export const retrieveTelemetryData = (parkId, thermographyId) => {
  return axios.get(
    `/places/parks/${parkId}/thermographies/${thermographyId}/telemetry`
  );
};
