// UserList.js
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes, faUser } from "@fortawesome/free-solid-svg-icons";
import {
  UsersContainer,
  UserCard,
  UserInfo,
  UserText,
  Username,
  IconContainer,
} from "../../styles/search/userListComponents";

/**
 * UserList Component
 *
 * This component displays a list of users in a styled container with user cards.
 *
 * @component
 * @param {Object[]} users - An array of user objects to display.
 * @param {number} users.id - The unique identifier of the user.
 * @param {string} users.email - The user's email address.
 * @param {string} users.username - The user's username.
 * @param {boolean} users.isSelected - Whether the user is selected or not.
 * @param {function} onToggleSelection - A function to handle user selection toggles.
 * @returns {JSX.Element} - The rendered UserList component.
 */
function UserList({ users = [], onToggleSelection }) {
  return (
    <UsersContainer>
      {users.map(
        ({ id, email, username, isSelected }) =>
          email &&
          username && (
            <UserCard key={id} onClick={() => onToggleSelection(id)}>
              <UserInfo>
                <FontAwesomeIcon
                  icon={faUser}
                  style={{
                    color: "#b8d669",
                    marginRight: "5px",
                    paddingRight: "5px",
                    marginLeft: "5px",
                    paddingLeft: "5px",
                  }}
                />
                <Username isSelected={isSelected}>{username}</Username>
                <UserText isSelected={isSelected}> ({email})</UserText>
              </UserInfo>
              <IconContainer>
                {isSelected ? (
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{ color: "green", marginRight: "10px" }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faTimes}
                    style={{ color: "red", marginRight: "10px" }}
                  />
                )}
              </IconContainer>
            </UserCard>
          )
      )}
    </UsersContainer>
  );
}

export default UserList;
