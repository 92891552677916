import React, { useState } from "react";

import LoginForm from "../../components/LoginForm";
import RegisterForm from "../../components/RegisterForm";

import {
  Body,
  Container,
  SignUpContainer,
  SignInContainer,
  OverlayContainer,
  Overlay,
  LeftOverlayPanel,
  RightOverlayPanel,
  Title,
  Paragraph,
  GhostButton,
} from "../../styles/page/loginRegisterComponents";

/**
 * Welcome component represents the welcome page.
 * Renders the sign-up and sign-in sections, and the overlay for switching between them.
 * @returns {JS.Element} - Rendered component.
 */
function Welcome() {
  // Define state for sign-in status
  const [signIn, setSignIn] = useState(false);
  // Function to toggle sign-in status
  const toggleSignIn = () => setSignIn(!signIn);

  return (
    <Body>
      <Container>
        {/* Sign-up section */}
        <SignUpContainer signedIn={signIn}>
          <RegisterForm />
        </SignUpContainer>

        {/* Sign-in section */}
        <SignInContainer signedIn={signIn}>
          <LoginForm setSignIn={setSignIn} />
        </SignInContainer>

        {/* Overlay section */}
        <OverlayContainer signedIn={signIn}>
          <Overlay signedIn={signIn}>
            {/* Left panel of the overlay */}
            <LeftOverlayPanel signedIn={signIn}>
              <Title>Καλώς ήλθατε!</Title>
              <Paragraph>
                Εγγραφείτε στην πλατφόρμα καταχωρώντας τα προσωπικά σας στοιχεία
              </Paragraph>
              {/* Button to switch to sign-in */}
              <GhostButton onClick={() => toggleSignIn(true)}>
                Εισοδος
              </GhostButton>
            </LeftOverlayPanel>

            {/* Right panel of the overlay */}
            <RightOverlayPanel signedIn={signIn}>
              <Title>Καλώς ήλθατε!</Title>
              <Paragraph>
                Συνδεθείτε στην πλατφόρμα καταχωρώντας τα προσωπικά σας στοιχεία
              </Paragraph>
              {/* Button to switch to sign-up */}
              <GhostButton onClick={() => toggleSignIn(false)}>
                Εγγραφη
              </GhostButton>
            </RightOverlayPanel>
          </Overlay>
        </OverlayContainer>
      </Container>
    </Body>
  );
}

export default Welcome;
