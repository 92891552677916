import styled from "styled-components";

import { BaseParagraph } from "../sharedStyles";

export const InfoModalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  zindex: 1020;
  backgroundcolor: "rgba(0, 0, 0, 0.5)";
`;

export const InfoModalContent = styled.div`
  position: relative;
  max-width: 100%;
  overflow-y: auto;
  border-radius: 8px;
  background-color: white;
`;

export const InfoModalParagraph = styled(BaseParagraph)`
  color: "#ab7200";
  text-align: "center";
  margin-top: "2.5%";
`;
