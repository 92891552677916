import React from "react";

import Navbar from "./NavBar";

/**
 * Layout component that represents the overall application layout.
 * @param {Object} props - Component props.
 * @returns {JS.Element} - Rendered component.
 */
const Layout = ({ children }) => {
  return (
    <div>
      {/* Render the Navbar component */}
      <Navbar />
      {/* Render the children components */}
      {children}
    </div>
  );
};

export default Layout;
