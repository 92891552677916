import React from "react";
import ReactModal from "react-modal";

import { InfoModalContainer, InfoModalContent, InfoModalParagraph } from "../../styles/modals/infoModalComponents";

/**
 * ErrorModal component represents the error modal.
 * Renders a modal with an error message.
 * @param {Object} props - Component props.
 * @returns {JS.Element} - Rendered component.
 */
function InfoModal({ isOpen, onRequestClose, infoMessage }) {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      ariaHideApp={false}
      style={{
        overlay: {
          zIndex: 1020,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        content: {
          position: "absolute",
          textAlign: "center",
          maxWidth: "100%",
          maxHeight: "18%",
          overflowY: "auto",
          marginTop: 45,
          boxSizing: "border-box",
          borderRadius: "8px",
          backgroundColor: "white",
        },
      }}
    >
      <InfoModalContainer>
        <InfoModalContent>
          <InfoModalParagraph>{infoMessage}</InfoModalParagraph>
        </InfoModalContent>
      </InfoModalContainer>
    </ReactModal>
  );
}

export default InfoModal;
