/**
 * Handles API errors and updates the error message and error modal visibility.
 * @param {Object} error - The Axios error object.
 * @param {Function} setErrorMessage - Function to set the error message in the component state.
 * @param {Function} setIsErrorModalOpen - Function to control the error modal's visibility.
 */
export const handleApiError = (error, setErrorMessage, setIsErrorModalOpen) => {
  if (error.response) {
    if (error.response.status >= 400 && error.response.status <= 499) {
      setErrorMessage(error.response.data.error.message);
    } else if (500 <= error.response.status <= 599) {
      setErrorMessage("Κάτι πήγε στραβά. Παρακαλώ, δοκιμάστε ξανά αργότερα!");
    }
    setIsErrorModalOpen(true);
  } else {
    console.log(error);
  }
};
