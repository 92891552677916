import { LoadingSpinnerOverlay, LoadingSpinnerContainer } from "../styles/components/loadingSpinnerComponents";


const LoadingSpinner = () => {
  return (
    <LoadingSpinnerOverlay>
      <LoadingSpinnerContainer />
    </LoadingSpinnerOverlay>
  );
}

export default LoadingSpinner;