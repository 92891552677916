import { handleApiError } from "../apiErrorHandling";

/**
 * Handles a successful retrieval of parks from the API.
 * @param {Object} response - The Axios response object.
 * @param {Function} setParks - Function to set the parks in the component state.
 */
export const retrieveParksSucc = (response, setParks) => {
  if (response.status === 200) {
    setParks(response.data);
  }
};

/**
 * Handles an error during the retrieval of parks from the API.
 * @param {Object} error - The Axios error object.
 * @param {Function} setErrorMessage - Function to set an error message in the component state.
 * @param {Function} setIsErrorModalOpen - Function to control the error modal's visibility.
 */
export const retrieveParksErr = (
  error,
  setErrorMessage,
  setIsErrorModalOpen
) => {
  handleApiError(error, setErrorMessage, setIsErrorModalOpen);
};

/**
 * Handles a successful retrieval of a specific park from the API.
 * @param {Object} response - The Axios response object.
 * @param {Function} setPark - Function to set the specific park data in the component state.
 */
export const retrieveParkSucc = (response, setPark, setPrivate) => {
  if (response.status === 200) {
    setPark(response.data);
    setPrivate(response.data.is_private);
  }
};

/**
 * Handles an error during the retrieval of a specific park from the API.
 * @param {Object} error - The Axios error object.
 * @param {Function} setErrorMessage - Function to set an error message in the component state.
 * @param {Function} setIsErrorModalOpen - Function to control the error modal's visibility.
 */
export const retrieveParkErr = (
  error,
  setErrorMessage,
  setIsErrorModalOpen
) => {
  handleApiError(error, setErrorMessage, setIsErrorModalOpen);
};

/**
 * Handles a successful deletion of a specific park from the API.
 * @param {Object} response - The Axios response object.
 * @param {Function} navigate - Function to navigate to a different route/page.
 */
export const deleteParkSucc = (response, navigate) => {
  navigate("/park-oversight");
};

/**
 * Handles an error during the deletion of a specific park from the API.
 * @param {Object} error - The Axios error object.
 * @param {Function} setErrorMessage - Function to set an error message in the component state.
 * @param {Function} setIsErrorModalOpen - Function to control the error modal's visibility.
 */
export const deleteParkErr = (error, setErrorMessage, setIsErrorModalOpen) => {
  handleApiError(error, setErrorMessage, setIsErrorModalOpen);
};

/**
 * Handles a successful creation of a new park via the API.
 * @param {Object} response - The Axios response object.
 * @param {Function} navigate - Function to navigate to a different route/page.
 */
export const createParkSucc = (response, navigate) => {
  if (response.status === 201) {
    navigate("/park-oversight");
  }
};

/**
 * Handles an error during the creation of a new park via the API.
 * @param {Object} error - The Axios error object.
 * @param {Function} setErrorMessage - Function to set an error message in the component state.
 * @param {Function} setIsErrorModalOpen - Function to control the error modal's visibility.
 */
export const createParkErr = (error, setErrorMessage, setIsErrorModalOpen) => {
  handleApiError(error, setErrorMessage, setIsErrorModalOpen);
};

/**
 * Handles a successful update of park information via the API.
 * @param {Object} response - The Axios response object.
 */
export const updateParkSucc = (response, updatedParkData, setPark) => {
  if (response.status === 200) {
    // navigate("/park-oversight");
    // Update the park's data by merging the updated fields with the existing data
    setPark((prevPark) => ({
      ...prevPark,
      ...updatedParkData,
    }));
  }
};

/**
 * Handles an error during the update of park information via the API.
 * @param {Object} error - The Axios error object.
 * @param {Function} setErrorMessage - Function to set an error message in the component state.
 * @param {Function} setIsErrorModalOpen - Function to control the error modal's visibility.
 */
export const updateParkError = (
  error,
  setErrorMessage,
  setIsErrorModalOpen
) => {
  handleApiError(error, setErrorMessage, setIsErrorModalOpen);
};
