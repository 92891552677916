import styled from "styled-components";

import {
  PageContainer,
  BaseForm,
  BaseTitle,
  BaseParagraph,
  BaseInput,
  BaseButton,
} from "../sharedStyles";

export const Body = styled(PageContainer)`
  height: 100vh;
  margin: -20px 0 50px;
`;

export const Container = styled(PageContainer)`
  background-color: #fff;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  width: 800px;
  max-width: 100%;
  min-height: 500px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
`;

const TransContainer = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 50%;
`;

export const SignUpContainer = styled(TransContainer)`
  left: 0;
  opacity: 0;
  z-index: 1;
  transition: all 0.6s ease-in-out;
  ${(props) =>
    props.signedIn !== true
      ? `
   transform: translateX(100%);
   opacity: 1;
   z-index: 5;
 `
      : null}
`;

export const SignInContainer = styled(TransContainer)`
  left: 0;
  z-index: 2;
  transition: all 0.6s ease-in-out;
  ${(props) =>
    props.signedIn !== true ? `transform: translateX(100%);` : null}
`;

export const OverlayContainer = styled(TransContainer)`
  left: 50%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
  ${(props) =>
    props.signedIn !== true ? `transform: translateX(-100%);` : null}
`;

export const OverlayPanel = styled(TransContainer)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
`;

export const LeftOverlayPanel = styled(OverlayPanel)`
  transform: translateX(-20%);
  ${(props) => (props.signedIn !== true ? `transform: translateX(0);` : null)}
`;

export const RightOverlayPanel = styled(OverlayPanel)`
  right: 0;
  transform: translateX(0);
  ${(props) => (props.signedIn !== true ? `transform: translateX(20%);` : null)}
`;

export const Overlay = styled.div`
  background: #75ff8f;
  background: -webkit-linear-gradient(to right, #ffaa41, #75ff8f);
  background: linear-gradient(to right, #ffaa41, #75ff8f);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
  ${(props) => (props.signedIn !== true ? `transform: translateX(50%);` : null)}
`;

export const Title = styled(BaseTitle)``;

export const Paragraph = styled(BaseParagraph)`
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  margin: 20px 0 30px;
`;

export const Form = styled(BaseForm)`
  width: 100%;
`;

export const FormTitle = styled(BaseTitle)`
  font-size: 25;
  top: 20;
`;

export const FormInput = styled(BaseInput)`
  width: 100%;
`;

export const Label = styled.label`
  font-size: 10px;
  font-weight: 100;
  color: red;
  line-height: 12px;
  letter-spacing: 0.2px;
  margin: 3px 0 15px;
`;

export const Button = styled(BaseButton)`
  margin: 15px 0;
  border-radius: 20px;
  border: 1px solid #5bcf72;
  background-color: #5bcf72;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  &:hover {
    transform: scale(1.05);
  }
  &:active {
    transform: scale(0.95);
  }
  &:focus {
    outline: none;
  }
`;

export const GhostButton = styled(Button)`
  background-color: transparent;
  border-color: #ffffff;
`;
