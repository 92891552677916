import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { login } from "../api/calls/authCall";
import { loginSucc, loginErr } from "../api/responses/authResponse";

import ErrorModal from "./modal/ErrorModal";

import {
  Form,
  FormTitle,
  FormInput,
  Button,
} from "../styles/page/loginRegisterComponents";

/**
 * LoginForm component represents the login form.
 * @param {Object} props - Component props.
 * @returns {JS.Element} - Rendered component.
 */
function LoginForm({ setSignIn }) {
  // Hook for navigation
  const navigate = useNavigate();

  // State variables for login form
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");

  // State variables for error handling
  const [loginErrMsg, setLoginErrMsg] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  /**
   * Function that handles the login form submission.
   * Calls the login API function and performs necessary actions based on the response.
   * @param {Object} e - Event object.
   */
  const handleSignIn = (e) => {
    e.preventDefault();

    // Call the login API function
    login(loginEmail, loginPassword)
      .then((response) => {
        loginSucc(response, navigate);
      })
      .catch((error) => {
        loginErr(error, setLoginErrMsg, setIsErrorModalOpen);
      });
  };

  return (
    <Form onSubmit={handleSignIn}>
      {/* Error modal */}
      <ErrorModal
        className="modal"
        isOpen={isErrorModalOpen}
        onRequestClose={() => setIsErrorModalOpen(false)}
        errorMessage={loginErrMsg}
      />
      {/* Login form */}
      <FormTitle style={{ fontSize: 25, marginBottom: 15, color: "green" }}>
        Σύνδεση
      </FormTitle>
      <FormInput
        id="loginEmail"
        name="loginEmail"
        type="email"
        placeholder="Διεύθυνση ηλεκτρονικού ταχυδρομείου"
        value={loginEmail}
        onChange={(e) => setLoginEmail(e.target.value)}
        required
      />
      <FormInput
        id="loginPassword"
        name="loginPassword"
        type="password"
        placeholder="Κωδικός πρόσβασης"
        value={loginPassword}
        onChange={(e) => setLoginPassword(e.target.value)}
        required
      />
      {/* {loginErrMsg && <Label>{loginErrMsg}</Label>} */}
      <Button>Εισοδος</Button>
    </Form>
  );
}

export default LoginForm;
