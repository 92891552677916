import {
  ParkPageContainer,
  InvertersTitle,
  ThermographyTableContainer,
  ThermographyTable,
  ThermographyTableBody,
  ThermographyTableRow,
  ThermographyTableHeader,
  ThermographyTableCell,
  ThermographyDateCell,
  ThermographyButtonRow
} from "../../../../styles/page/parkComponents";

export const ThermographicEventsTabContent = ({
  thermographies,
  navigate,
  id,
}) => (
  <ParkPageContainer>
    <InvertersTitle>Θερμογραφικά Δεδομένα</InvertersTitle>
    <ThermographyTableContainer>
      {thermographies.length > 0 ? (
        <ThermographyTable>
          <ThermographyTableBody>
            <ThermographyTableRow>
              <ThermographyTableHeader>
                Κωδικός
              </ThermographyTableHeader>
              <ThermographyTableHeader>
                Δημιουργία
              </ThermographyTableHeader>
              <ThermographyTableHeader>
                Ενημέρωση
              </ThermographyTableHeader>
            </ThermographyTableRow>
            {thermographies.map((thermography) => (
              <ThermographyButtonRow
                key={thermography.id}
                onClick={() =>
                  navigate(
                    `/parks/${id}/thermographies/${thermography.id}/supervision`
                  )
                }
              >
                <ThermographyTableCell>
                  {thermography.id}
                </ThermographyTableCell>
                <ThermographyDateCell>
                  {new Date(thermography.created_at).toLocaleDateString(
                    "el-GR",
                    {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    }
                  )}
                </ThermographyDateCell>
                <ThermographyDateCell>
                  {new Date(thermography.updated_at).toLocaleDateString(
                    "el-GR",
                    {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    }
                  )}
                </ThermographyDateCell>
              </ThermographyButtonRow>
            ))}
          </ThermographyTableBody>
        </ThermographyTable>
      ) : (
        <div>
          <br />
          <br />
          <p>Δεν βρέθηκαν θερμογραφικά δεδομένα.</p>
          <br />
          <br />
        </div>
      )}
    </ThermographyTableContainer>
  </ParkPageContainer>
);
