import React from "react";

import {AreaInfoBackground, AreaInfoWrapper, AreaInfoID, AreaInfoDescription } from "../../styles/page/areaComponents"

export const AreaInfo = ({ area }) => {
  return (
    <AreaInfoBackground>
      <AreaInfoWrapper>
        <AreaInfoID>Κωδικός: {area.id}</AreaInfoID>
        <AreaInfoDescription>Περιγραφή: {area.description}</AreaInfoDescription>
      </AreaInfoWrapper>
    </AreaInfoBackground>
  );
};
