import { RandomColor } from "./chartLineColors";

export const meteorologyChartDatasets = (data) => [
  {
    label: "Διεύθυνση Ανέμου",
    data: data.map((item) => item.wind_direction),
    borderColor: "rgba(255, 99, 132, 1)",
    backgroundColor: "rgba(255, 99, 132, 0.2)",
    unit: "°",
  },
  {
    label: "Θερμοκρασία",
    data: data.map((item) => item.temperature),
    borderColor: "rgba(54, 162, 235, 1)",
    backgroundColor: "rgba(54, 162, 235, 0.2)",
    unit: "°C",
  },
  {
    label: "Ηλιακή Ενέργεια",
    data: data.map((item) => item.solar),
    borderColor: "rgba(255, 226, 5, 1)",
    backgroundColor: "rgba(255, 226, 5, 0.2)",
    unit: "W/m²",
  },
  {
    label: "Σχετική Υγρασία",
    data: data.map((item) => item.humidity),
    borderColor: "rgba(75, 192, 192, 1)",
    backgroundColor: "rgba(75, 192, 192, 0.2)",
    unit: "%",
  },
  {
    label: "Ταχύτητα Ανέμου",
    data: data.map((item) => item.wind_speed),
    borderColor: "rgba(153, 102, 255, 1)",
    backgroundColor: "rgba(153, 102, 255, 0.2)",
    unit: "m/s",
  },
];

export const telemetryInverterChartDatasets = (data) => {
  if (data && data.inverters) {
    let colorInst = new RandomColor(20, (90 / data.mppts.length));
    let idx = 0;
    return data.inverters.map((inverter) => {
      let color = colorInst.SampleColor();
      idx += 1;
      return {
        label: inverter.inverter_id,
        data: inverter.series.map(series => ({
          x: series.timestamp, y: series.value
        })),
        borderColor: color.SetAlpha(1).String(),
        backgroundColor: color.SetAlpha(0.2).String(),
        power: inverter.power,
        hidden: !(idx === 1),
      }
    });
  } else {
    return [];
  }
};

export const telemetryMpptVoltageChartDatasets = (data) => {
  if (data && data.mppts) {
    let colorInst = new RandomColor(0, (90 / data.mppts.length));
    let idx = 0;
    return data.mppts.map((mppt) => {
      let color = colorInst.SampleColor();
      idx += 1;
      return {
        label: mppt.mppt_id,
        data: mppt.series.map((series) => ({
          x: series.timestamp,
          y: series.voltage,
        })),
        borderColor: color.SetAlpha(1).String(),
        backgroundColor: color.SetAlpha(0.2).String(),
        power: null,
        hidden: !(idx === 1),
      }
    });
  } else {
    return [];
  }
};

export const telemetryMpptEnergyChartDatasets = (data) => {
  if (data && data.mppts) {
    let colorInst = new RandomColor(0, (90 / data.mppts.length));
    // Updated property name from data.mpps to data.mppts
    let idx = 0;
    return data.mppts.map((mppt) => {
      let color = colorInst.SampleColor();
      idx += 1;
      return {
        label: mppt.mppt_id,
        data: mppt.series.map((series) => ({
          x: series.timestamp,
          y: series.energy,
        })),
        borderColor: color.SetAlpha(1).String(),
        backgroundColor: color.SetAlpha(0.2).String(),
        power: null,
        hidden: !(idx === 1),
      }
    });
  } else {
    return [];
  }
};