import axios from "axios";

/**
 * Function to retrieve a list of inverters for a specific park.
 * @param {string} parkId - The ID of the park for which to retrieve inverters.
 * @returns {Promise} - Axios promise object representing the GET request.
 */
export const retrieveInverters = (parkId) => {
  return axios.get(`places/parks/${parkId}/inverters`);
};

/**
 * Function to create a new inverter for a specific park.
 * @param {string} parkId - The ID of the park in which to create the inverter.
 * @param {string} manufacturer - The manufacturer of the inverter.
 * @param {string} model - The model of the inverter.
 * @param {string} power - The power rating of the inverter.
 * @returns {Promise} - Axios promise object representing the POST request.
 */
export const createInverter = (parkId, manufacturer, model, power) => {
  // const data = {
  //     "manufacturer": manufacturer,
  //     "model": model,
  //     "power": power,
  // }
  return axios.post(`places/parks/${parkId}/inverters`);
};

/**
 * Function to retrieve information about a specific inverter in a park.
 * @param {string} parkId - The ID of the park containing the inverter.
 * @param {string} inverterId - The ID of the inverter to retrieve.
 * @returns {Promise} - Axios promise object representing the GET request.
 */
export const retrieveInverter = (parkId, inverterId) => {
  return axios.get(`places/parks/${parkId}/inverters/${inverterId}`);
};

/**
 * Function to handle the deletion of a specific inverter.
 * @param {string} parkId - The ID of the park containing the inverter.
 * @param {string} inverterId - The ID of the inverter to delete.
 * @returns {Promise} - Axios promise object representing the DELETE request.
 */
export const deleteInverter = (parkId, inverterId) => {
  // Make a DELETE request to delete the specific inverter
  return axios.delete(`places/parks/${parkId}/inverters/${inverterId}`);
};
