import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import UserAuthorization from "../../../../authorization/UserAuthorization";

import { retrievePark } from "../../../../api/calls/parkCall";
import {
  retrieveParkErr,
  retrieveParkSucc,
} from "../../../../api/responses/parkResponse";

import { ThermographyDetailsTabContent } from "./thermography_tabs/ThermographyDetailsTabContent";
import { MeteorologyDetailsTabContent } from "./thermography_tabs/MeteorologyDetailsTabContent";
import { TelemetryDetailsTabContent } from "./thermography_tabs/TelemetryDetailsTabContent";

import Layout from "../../../../utils/Layout";
import LoadingSpinner from "../../../../utils/LoadingSpinner";
import Tabs from "../../../../utils/Tabs";
import ErrorModal from "../../../../components/modal/ErrorModal";

import { ParkPageContainer, ParkTitle } from "../../../../styles/page/parkComponents";

export default function Thermography() {
  const navigate = useNavigate();

  const { parkId, thermographyId } = useParams();

  const [park, setPark] = useState({});
  const [is_private, setPrivate] = useState(park.is_private || false);
  const [isLoading, setIsLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  useEffect(() => {
    if (!UserAuthorization.isAuthenticated()) {
      navigate("/welcome");
    } else {
      fetchParkDetails(parkId);
    }
  }, [parkId, thermographyId, navigate]);

  const fetchParkDetails = (parkId) => {
    setIsLoading(true);
    retrievePark(parkId)
      .then((response) => {
        retrieveParkSucc(response, setPark, setPrivate);
      })
      .catch((error) => {
        retrieveParkErr(error, setErrorMessage, setIsErrorModalOpen);
      })
      .finally(() => {
        setIsLoading(false); // Set loading to false when the request completes
      });
  };

  if (isLoading) {
    // Display loading spinner while fetching data
    return (
      <ParkPageContainer>
        <LoadingSpinner />
      </ParkPageContainer>
    );
  }

  return (
    <Layout>
      <ErrorModal
        className="modal"
        isOpen={isErrorModalOpen}
        onRequestClose={() => setIsErrorModalOpen(false)}
        errorMessage={errorMessage}
      />
      <ParkPageContainer>
        <ParkTitle>{park.name}</ParkTitle>

        <Tabs>
          <ThermographyDetailsTabContent
            title="Θερμογραφία"
            parkId={parkId}
            thermographyId={thermographyId}
          />
          <MeteorologyDetailsTabContent
            title="Μετεωρολογία"
            parkId={parkId}
            thermographyId={thermographyId}
          />
          <TelemetryDetailsTabContent
            title="Ενέργεια"
            parkId={parkId}
            thermographyId={thermographyId}
          />
        </Tabs>
      </ParkPageContainer>
    </Layout>
  );
}
