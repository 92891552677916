import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  deleteArea,
  retrieveArea,
  updateArea,
  retrieveAreas,
} from "../../../api/calls/areaCall";
import {
  deleteAreaErr,
  deleteAreaSucc,
  retrieveAreaErr,
  retrieveAreaSucc,
  updateAreaSucc,
  updateAreaErr,
} from "../../../api/responses/areaResponse";

import { AreaInfo } from "../../../components/area/AreaInfo";

import Layout from "../../../utils/Layout";
import SearchUsers from "../../../components/search/SearchUsers";

import ConfirmationModal from "../../../components/modal/ConfirmationModal";
import ErrorModal from "../../../components/modal/ErrorModal";

import {
  AreaPageContainer,
  AreaDetailsTitle,
  AreaDetailsTableContainer,
  AreaDetailsTable,
  AreaDetailsTableBody,
  AreaDetailsTableRow,
  AreaDetailsTableHeader,
  AreaDetailsTableCell,
  AreaInfoContainer,
  AreaInfoRowContainer,
  AreaInfoCheckboxContainer,
  AreaInfoCheckboxLabel,
  AreaInfoStyledCheckbox,
  EditAreaInfoInput,
  EditAreaInfoStyledCheckbox,
  EditAreaInfoCheckboxLabel,
  EditAreaRadioContainer,
  EditAreaRadioInput,
  EditAreaRadioLabel,
  AreaDetailsDownloadButton,
  AreaDetailsDownloadImg,
  AreaInfoButtonsContainer,
  EditAreaInfoButtonsContainer,
  EditAreaInfoButtonContainer,
  AreaInfoDeleteButtonContainer,
  AreaInfoDeleteConfirmButton,
  AreaInfoDeleteCancelButton,
} from "../../../styles/page/areaComponents";

import downloadImg from "../../../../src/assets/download.png";

function AreaDetails() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [area, setArea] = useState({});

  // Initialize checkpoints based on area when not in edit mode
  const [checkpoints, setCheckpoints] = useState(area.checkpoints || {});
  //const [checkpoints, setCheckpoints] = useState({});

  const [is_private, setPrivate] = useState(area.is_private || {});

  const [showModal, setShowModal] = useState(false); // State for showing/hiding the modal
  const message = "Είστε σίγουροι ότι θέλετε να διαγράψετε την περιοχή;";

  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const [isEditing, setIsEditing] = useState(false); // State for showing/hiding the edit form
  const [editedArea, setEditedArea] = useState({
    name: "",
    description: "",
    checkpoints: {
      nearby_road: null,
      nearby_voltage_network: null,
    },
    is_private: null,
  });

  const areaCheckpointMapping = {
    nearby_road: "Ύπαρξη κοντινών οδών για πρόσβαση στο γήπεδο",
    nearby_voltage_network: "Ύπαρξη δικτύων χαμηλής ή μέσης τάσης στην περιοχή",
  };

  useEffect(() => {
    // Fetch area details when the component mounts
    fetchAreaDetails(id);
  }, [id]);

  const fetchAreaDetails = (areaId) => {
    retrieveArea(areaId)
      .then((response) => {
        retrieveAreaSucc(response, setArea, setCheckpoints, setPrivate);
      })
      .catch((error) => {
        retrieveAreaErr(error, setErrorMessage, setIsErrorModalOpen);
      });
  };

  const handleDeleteArea = (e) => {
    e.preventDefault();
    // Show the confirmation modal when the user clicks the delete button
    setShowModal(true);
  };

  const handleConfirmDelete = () => {
    deleteArea(id)
      .then((response) => {
        deleteAreaSucc(response, navigate);
      })
      .catch((error) => {
        deleteAreaErr(error, setErrorMessage, setIsErrorModalOpen);
      });

    // Hide the modal after deletion is complete
    setShowModal(false);
  };

  const handleCancelDelete = () => {
    // User canceled deletion, hide the modal
    setShowModal(false);
  };

  const handleEditArea = () => {
    setIsEditing(true);
    setEditedArea({
      name: area.name,
      description: area.description,
      checkpoints: {
        nearby_road: area.nearby_road,
        nearby_voltage_network: area.nearby_voltage_network,
      },
      is_private: area.is_private,
    });
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
  };

  const handleSaveEdit = () => {
    const { name, description } = editedArea;

    // Check if the name exists only when the user is editing the name
    if (name !== area.name) {
      checkAreaFieldExists(id, "name", name)
        .then((isNameExists) => {
          if (isNameExists) {
            setErrorMessage(
              "Το όνομα της περιοχής υπάρχει ήδη. Παρακαλώ, καταχωρήστε διαφορετικό όνομα."
            );
            setIsErrorModalOpen(true);
          } else {
            const updatedAreaData = {
              name,
              description,
              checkpoints,
              is_private,
            };

            updateArea(id, updatedAreaData)
              .then((response) => {
                updateAreaSucc(response, updatedAreaData, setArea);
              })
              .catch((error) => {
                updateAreaErr(error, setErrorMessage, setIsErrorModalOpen);
              });
          }
        })
        .catch((error) => {
          console.error("Error checking area name: ", error);
        });
    } else {
      // If the user is not editing the name, proceed with updating other fields like description without checking.
      const updatedAreaData = {
        name,
        description,
        checkpoints,
        is_private,
      };

      updateArea(id, updatedAreaData)
        .then((response) => {
          updateAreaSucc(response, updatedAreaData, setArea);
        })
        .catch((error) => {
          updateAreaErr(error, setErrorMessage, setIsErrorModalOpen);
        });
    }

    setIsEditing(false);
  };

  const checkAreaFieldExists = (areaId, field, value) => {
    return retrieveAreas()
      .then((response) => {
        const existingAreas = response.data;
        // Check if the specified field value exists in the existing areas
        const exists = existingAreas.some((area) => area[field] === value);
        return exists;
      })
      .catch((error) => {
        console.error("Error retrieving areas:", error);
        return false;
      });
  };

  const download3Dmodel = () => {
    if (area.job && area.job.model) {
      window.open(area.job.model, "_blank");
    }
  };

  const downloadOrthophoto = () => {
    if (area.job && area.job.orthophoto) {
      window.open(area.job.orthophoto, "_blank");
    }
  };

  const downloadDsm = () => {
    if (area.job && area.job.dsm) {
      window.open(area.job.dsm, "_blank");
    }
  };

  const downloadDtm = () => {
    if (area.job && area.job.dtm) {
      window.open(area.job.dtm, "_blank");
    }
  };

  const handleCheckpointChange = (e) => {
    const checkpointName = e.target.name;
    const isChecked = e.target.checked;

    // Update the editedCheckpoints state with the new value
    setCheckpoints((prevEditedCheckpoints) => ({
      ...prevEditedCheckpoints,
      [checkpointName]: isChecked,
    }));
  };

  const cardinalDirectionMapping = {
    N: "Β",
    NE: "ΒΑ",
    E: "Α",
    SE: "ΝΑ",
    S: "Ν",
    SW: "ΝΔ",
    W: "Δ",
    NW: "ΒΔ",
  };

  return (
    <Layout>
      <ConfirmationModal
        isOpen={showModal}
        onCancel={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        message={message}
      />

      <ErrorModal
        className="modal"
        isOpen={isErrorModalOpen}
        onRequestClose={() => setIsErrorModalOpen(false)}
        errorMessage={errorMessage}
      />

      <AreaPageContainer>
        <AreaDetailsTitle>{area.name}</AreaDetailsTitle>

        <SearchUsers placeId={area.id} />

        <AreaInfoContainer>
          {isEditing ? (
            <>
              <AreaInfoRowContainer>
                <EditAreaInfoInput
                  type="text"
                  placeholder="Όνομα"
                  value={editedArea.name}
                  onChange={(e) =>
                    setEditedArea({ ...editedArea, name: e.target.value })
                  }
                />
                <EditAreaInfoInput
                  type="text"
                  placeholder="Περιγραφή"
                  value={editedArea.description}
                  onChange={(e) =>
                    setEditedArea({
                      ...editedArea,
                      description: e.target.value,
                    })
                  }
                />
              </AreaInfoRowContainer>

              <AreaInfoRowContainer>
                {Object.keys(checkpoints).map((checkpointName, index) => (
                  <EditAreaInfoCheckboxLabel key={index}>
                    <EditAreaInfoStyledCheckbox
                      type="checkbox"
                      name={checkpointName}
                      checked={checkpoints[checkpointName]}
                      onChange={(e) =>
                        handleCheckpointChange(e, checkpointName)
                      }
                    />
                    {areaCheckpointMapping[checkpointName]}
                  </EditAreaInfoCheckboxLabel>
                ))}

                <EditAreaRadioContainer>
                  <EditAreaRadioLabel>
                    <EditAreaRadioInput
                      type="radio"
                      id="privateTab"
                      name="privateRadio"
                      checked={is_private}
                      onChange={() => setPrivate(true)}
                    ></EditAreaRadioInput>{" "}
                    Ιδιωτικό
                  </EditAreaRadioLabel>
                  <EditAreaRadioLabel>
                    <EditAreaRadioInput
                      type="radio"
                      id="publicTab"
                      name="privateRadio"
                      checked={!is_private}
                      onChange={() => setPrivate(false)}
                    ></EditAreaRadioInput>{" "}
                    Δημόσιο
                  </EditAreaRadioLabel>
                </EditAreaRadioContainer>
              </AreaInfoRowContainer>

              <EditAreaInfoButtonsContainer>
                <AreaInfoDeleteConfirmButton onClick={handleSaveEdit}>
                  Αποθήκευση
                </AreaInfoDeleteConfirmButton>
                <AreaInfoDeleteCancelButton onClick={handleCancelEdit}>
                  Ακύρωση
                </AreaInfoDeleteCancelButton>
              </EditAreaInfoButtonsContainer>
            </>
          ) : (
            <>
              <AreaInfo area={area} />

              <AreaInfoRowContainer>
                {Object.keys(checkpoints).map((checkpointName, index) => (
                  <AreaInfoCheckboxContainer key={index}>
                    <AreaInfoCheckboxLabel>
                      <AreaInfoStyledCheckbox
                        type="checkbox"
                        name={checkpointName}
                        checked={checkpoints[checkpointName]}
                        readOnly
                        disabled
                      />
                      {areaCheckpointMapping[checkpointName]}
                    </AreaInfoCheckboxLabel>
                  </AreaInfoCheckboxContainer>
                ))}
              </AreaInfoRowContainer>

              <AreaInfoButtonsContainer>
                <EditAreaInfoButtonContainer
                  title="Edit"
                  onClick={handleEditArea}
                ></EditAreaInfoButtonContainer>

                <AreaInfoDeleteButtonContainer
                  title="Delete"
                  onClick={handleDeleteArea}
                ></AreaInfoDeleteButtonContainer>
              </AreaInfoButtonsContainer>
            </>
          )}
        </AreaInfoContainer>

        <AreaDetailsTitle style={{ fontSize: 25 }}>
          Αποτελέσματα
        </AreaDetailsTitle>
        <AreaDetailsTableContainer>
          <AreaDetailsTable>
            <AreaDetailsTableBody>
              <AreaDetailsTableRow>
                <AreaDetailsTableHeader>
                  Τρισδιάστατο Μοντέλο
                </AreaDetailsTableHeader>
                <AreaDetailsTableHeader>Ορθοφωτογραφία</AreaDetailsTableHeader>
                <AreaDetailsTableHeader>
                  Ψηφιακό Μοντέλο Επιφάνειας
                </AreaDetailsTableHeader>
                <AreaDetailsTableHeader>
                  Ψηφιακό Μοντέλο Εδάφους
                </AreaDetailsTableHeader>
              </AreaDetailsTableRow>

              <AreaDetailsTableRow>
                <AreaDetailsTableCell>
                  {area.name} 3D{" "}
                  <AreaDetailsDownloadButton onClick={download3Dmodel}>
                    <AreaDetailsDownloadImg src={downloadImg} alt="Download" />
                  </AreaDetailsDownloadButton>
                </AreaDetailsTableCell>
                <AreaDetailsTableCell>
                  {area.name} Ortho{" "}
                  <AreaDetailsDownloadButton onClick={downloadOrthophoto}>
                    <AreaDetailsDownloadImg src={downloadImg} alt="Download" />
                  </AreaDetailsDownloadButton>
                </AreaDetailsTableCell>
                <AreaDetailsTableCell>
                  {area.name} DSM{" "}
                  <AreaDetailsDownloadButton onClick={downloadDsm}>
                    <AreaDetailsDownloadImg src={downloadImg} alt="Download" />
                  </AreaDetailsDownloadButton>
                </AreaDetailsTableCell>
                <AreaDetailsTableCell>
                  {area.name} DTM{" "}
                  <AreaDetailsDownloadButton onClick={downloadDtm}>
                    <AreaDetailsDownloadImg src={downloadImg} alt="Download" />
                  </AreaDetailsDownloadButton>
                </AreaDetailsTableCell>
              </AreaDetailsTableRow>
            </AreaDetailsTableBody>
          </AreaDetailsTable>
        </AreaDetailsTableContainer>

        <AreaDetailsTableContainer style={{ marginTop: "20px" }}>
          <AreaDetailsTable>
            <AreaDetailsTableBody>
              <AreaDetailsTableRow>
                <AreaDetailsTableCell>
                  Κατεβάστε{" "}
                  <a href="https://filebrowser.homelab.up2metric.com/share/x6NQmvKg">
                    εδώ{" "}
                  </a>{" "}
                  την οπτικοποίηση 4D γραφήματος
                </AreaDetailsTableCell>
              </AreaDetailsTableRow>
            </AreaDetailsTableBody>
          </AreaDetailsTable>
        </AreaDetailsTableContainer>

        <AreaDetailsTitle style={{ fontSize: 25 }}>
          Στατιστικά Στοιχεία
        </AreaDetailsTitle>
        <AreaDetailsTableContainer>
          <AreaDetailsTable>
            <AreaDetailsTableBody>
              <AreaDetailsTableRow>
                <AreaDetailsTableHeader>
                  Βασικός Προσανατολισμός
                </AreaDetailsTableHeader>
                <AreaDetailsTableHeader>
                  Βασική Διεύθυνση
                </AreaDetailsTableHeader>
                <AreaDetailsTableHeader>Μέγιστη Κλίση</AreaDetailsTableHeader>
                <AreaDetailsTableHeader>
                  Μέγιστη Υψομετρική Διαφορά
                </AreaDetailsTableHeader>
              </AreaDetailsTableRow>

              <AreaDetailsTableRow>
                <AreaDetailsTableCell>
                  {area.metainformation && area.metainformation.direction
                    ? `${area.metainformation.direction}°`
                    : "Μη διαθέσιμο"}
                </AreaDetailsTableCell>
                <AreaDetailsTableCell>
                  {area.metainformation &&
                  area.metainformation.cardinal_direction
                    ? `${
                        cardinalDirectionMapping[
                          area.metainformation.cardinal_direction
                        ]
                      }`
                    : "Μη διαθέσιμο"}
                </AreaDetailsTableCell>
                <AreaDetailsTableCell>
                  {" "}
                  {area.metainformation && area.metainformation.slope_degrees
                    ? `${area.metainformation.slope_degrees}°`
                    : "Μη διαθέσιμο"}
                </AreaDetailsTableCell>
                <AreaDetailsTableCell>
                  {area.metainformation &&
                  area.metainformation.max_elevation_difference
                    ? `${area.metainformation.max_elevation_difference} m`
                    : "Μη διαθέσιμο"}
                </AreaDetailsTableCell>
              </AreaDetailsTableRow>
            </AreaDetailsTableBody>
          </AreaDetailsTable>
        </AreaDetailsTableContainer>
      </AreaPageContainer>
    </Layout>
  );
}

export default AreaDetails;
