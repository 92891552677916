import styled from "styled-components";

import { BaseContainer, BaseParagraph, BaseButton } from "../sharedStyles";


export const Wrapper = styled.div`
  text-align: center;
  padding: 20px;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent black background */
  z-index: 1; /* Ensure the overlay is above other content */
  display: ${(props) => (props.isPopupOpen ? "block" : "none")};
`;

export const Popup = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 30px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 900px;
  width: 100%;
  z-index: 2; /* Ensure the overlay is above other content */
`;

export const Button = styled(BaseButton)`
  padding: 12px 24px;
  margin: 10px;
  border: none;
  background-color: #b8d669;
  color: #fff;
  border-radius: 10px; /* Matched border-radius with Popup */
  transition: background-color 0.3s; /* Smooth color transition on hover */

  &:hover {
    background-color: #ffaa41; /* Darker blue on hover */
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

export const InviteButton = styled(Button)`
  font-family: inherit;
  position: absolute;
  top: 120px;
  right: 50px;
`;

export const InputWrapper = styled(BaseContainer)`
  justify-content: space-between;
  margin-bottom: 20px; /* Increased margin for better spacing */
`;

export const Input = styled.input`
  font-family: inherit;
  flex: 1;
  padding: 14px; /* Increased padding for better input field size */
  border: 2px solid #ccc; /* Slightly thicker border for better visibility */
  border-radius: 10px;
  font-size: 16px; /* Increased font size for better readability */
  transition: border-color 0.3s; /* Smooth border color transition on focus */

  &:focus {
    outline: none; /* Removed the default focus outline */
    border-color: #b8d669; /* Highlight border on focus */
  }
`;

export const CloseButton = styled(BaseButton)`
  background: none;
  border: none;
  font-size: 24px; /* Larger close button */
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const InfoText = styled(BaseParagraph)`
  font-size: 24px;
  color: #306b3e;
  margin-bottom: 20px;
  font-weight: bold;
`;
