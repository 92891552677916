import styled from "styled-components";
import {
  PageContainer,
  BaseTitle,
  BaseParagraph,
  BaseButton,
} from "../sharedStyles";

export const HomeBody = styled(PageContainer)`
  margin-top: 70px;
`;

export const HomeTitle = styled(BaseTitle)`
  font-size: 32px;
  margin: 40px 0;
`;

export const HomeCardsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
`;

export const HomeCard = styled(PageContainer)`
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: translateY(-5px);
  }
`;

export const HomeCardImage = styled.img`
  width: 250px;
  height: 250px;
  object-fit: cover;
  border-radius: 50%;
`;

export const HomeCardDescription = styled(BaseParagraph)`
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin-top: 20px;
`;

export const HomeButton = styled(BaseButton)`
  margin: 15px 0;
  border: none;
  background-color: transparent;
  color: #4aaa61;

  transition: transform 80ms ease-in;

  &:hover {
    transform: scale(1.1);
  }
  &:active {
    transform: scale(0.95);
  }
  &:focus {
    outline: none;
  }
`;
