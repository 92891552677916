import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faSearch,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";

import {
  invite,
  search,
  retrieveMembersOfPlace,
} from "../../api/calls/userCall";
import { searchUsersErr } from "../../api/responses/userResponse";
import UserList from "./UserList";

import ErrorModal from "../modal/ErrorModal";
import {
  Wrapper,
  InviteButton,
  Overlay,
  Popup,
  CloseButton,
  InputWrapper,
  Input,
  Button,
  InfoText,
} from "../../styles/search/searchUsersComponents";

/**
 * RetrieveUsers Component
 * This component allows users to search for and select users, then send an invasion request for selected users.
 * @param {int} placeId - The unique identifier of the place.
 * @returns {JSX.Element} - The RetrieveUsers component.
 */
function SearchUsers({ placeId }) {
  // State variables for managing user data and popup status
  const [users, setUsers] = useState([]);
  const [members, setMembers] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  /**
   * Function to open the popup.
   */
  const openPopup = () => {
    // Open the popup
    setIsPopupOpen(!isPopupOpen);
    // Retrieve the members of place
    retrieveMembersOfPlace(placeId)
      .then((response) => {
        setMembers(response.data);
      })
      .catch((error) => {
        searchUsersErr(error, setErrorMessage, setIsErrorModalOpen);
        setMembers([]);
      });
  };

  /**
   * Function to close the popup.
   */
  const closePopup = () => {
    setIsPopupOpen(false);
    setUsers([]);
    setMembers([]);
    setKeyword("");
  };

  /**
   * Fetches and updates users based on a provided keyword.
   */
  const fetchUsers = () => {
    search(keyword)
      .then((response) => {
        // Map the response data and set initial 'isSelected' state
        const updatedUsers = response.data.map((user) => ({
          ...user,
          isSelected: false,
        }));

        // Check if each user matches with any member
        const filterUpdatedUsers = updatedUsers.filter(
          (user) => !members.some((member) => member.id === user.id)
        );

        // Update the users and clear any error
        setUsers(filterUpdatedUsers);
      })
      .catch((error) => {
        // Handle errors and reset users
        searchUsersErr(error, setErrorMessage, setIsErrorModalOpen);
        setUsers([]);
      });
  };

  /**
   * Function to update the search keyword.
   * @param {string} keyword - The new search keyword.
   */
  const updateKeyword = (keyword) => {
    setKeyword(keyword);
    fetchUsers();
  };

  /**
   * Function to toggle user selection.
   * @param {string} userId - The ID of the user to toggle selection for.
   */
  const toggleUserSelection = (userId) => {
    const updatedUsers = users.map((user) =>
      user.id === userId ? { ...user, isSelected: !user.isSelected } : user
    );
    setUsers(updatedUsers);
  };

  /**
   * Function to handle sending the invasion request.
   */
  const handleSendInvite = () => {
    const selectedUsersToInvite = users.filter((user) => user.isSelected);
    // Send the invite
    selectedUsersToInvite.forEach((user) => {
      invite(placeId, user.id)
        .then((_) => {
          user.isSelected = false;
          // members.add(user)
        })
        .catch((error) => {
          searchUsersErr(error, setErrorMessage, setIsErrorModalOpen);
          setUsers([]);
        });
    });
    closePopup();
  };

  return (
    <Wrapper>
      <ErrorModal
        className="modal"
        isOpen={isErrorModalOpen}
        onRequestClose={() => setIsErrorModalOpen(false)}
        errorMessage={errorMessage}
      />
      {/* Use the className prop to apply custom styles to the button */}
      <InviteButton onClick={openPopup}>
        <FontAwesomeIcon icon={faSearch} style={{ marginRight: "10px" }} />{" "}
        Πρόσκληση Χρήστη
      </InviteButton>
      <Overlay isPopupOpen={isPopupOpen} onClick={closePopup} />
      {isPopupOpen && (
        <Popup>
          {/* Close button (FontAwesome close icon) */}
          <CloseButton onClick={closePopup}>
            <FontAwesomeIcon icon={faTimes} />
          </CloseButton>
          <InfoText>Πρόσκληση χρήστη</InfoText>
          <InputWrapper>
            {/* Input for searching */}
            <Input
              value={keyword}
              placeholder="Αναζήτηση χρήστη"
              onChange={(e) => updateKeyword(e.target.value)}
            />
            {/* Button for sending the invasion request */}
            <Button
              onClick={handleSendInvite}
              disabled={users.filter((user) => user.isSelected).length === 0}
            >
              Αποστολή{" "}
              <FontAwesomeIcon
                icon={faPaperPlane}
                style={{ marginLeft: "10px" }}
              />
            </Button>
          </InputWrapper>
          {/* User list component */}
          <UserList // Use the UserList component here
            users={users}
            onToggleSelection={toggleUserSelection}
          />
        </Popup>
      )}
    </Wrapper>
  );
}

export default SearchUsers;
