import { handleApiError } from "../apiErrorHandling";

/**
 * Handles a successful retrieval of MPPTs (Maximum Power Point Trackers) for a specific inverter in a park from the API.
 * @param {Object} response - The Axios response object.
 * @param {string} parkId - The ID of the park containing the inverter.
 * @param {string} inverterId - The ID of the inverter for which MPPTs are retrieved.
 * @param {Function} setMppts - Function to set the retrieved MPPTs in the component state.
 */
export const retrieveMpptsSucc = (response, parkId, inverterId, setMppts) => {
  if (response.status === 200) {
    const mpptData = response.data.map((item) => ({
      id: item.id,
      inverterId: inverterId, // Add inverterId
      parkId: parkId,
    }));

    setMppts(mpptData);
  }
};

/**
 * Handles an error during the retrieval of MPPTs (Maximum Power Point Trackers) for a specific inverter in a park from the API.
 * @param {Object} error - The Axios error object.
 * @param {Function} setErrorMessage - Function to set an error message in the component state.
 * @param {Function} setIsErrorModalOpen - Function to control the error modal's visibility.
 */
export const retrieveMpptsErr = (
  error,
  setErrorMessage,
  setIsErrorModalOpen
) => {
  handleApiError(error, setErrorMessage, setIsErrorModalOpen);
};
