import { React, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import UserAuthorization from "../../authorization/UserAuthorization";

import Layout from "../../utils/Layout";
import {
  HomeBody,
  HomeCardsContainer,
  HomeTitle,
  HomeCard,
  HomeCardImage,
  HomeCardDescription,
  HomeButton,
} from "../../styles/page/homeComponents";

import droneImg from "../../assets/drone_img.png";
import panelImg from "../../assets/panel_img.png";

/**
 * Home component represents the home page.
 * Renders a list of functionality cards.
 * @returns {JS.Element} - Rendered component.
 */
export default function Home() {
  const navigate = useNavigate();

  useEffect(() => {
    if (!UserAuthorization.isAuthenticated()) {
      navigate("/welcome");
    }
  }, []);

  return (
    <Layout>
      <HomeBody>
        <HomeTitle>Λειτουργίες</HomeTitle>
        <HomeCardsContainer>
          <Link to="/area-oversight">
            <HomeButton>
              <HomeCard>
                <HomeCardImage
                  src={droneImg}
                  alt="Card1"
                />
                <HomeCardDescription>Προκαταρκτικη Μελετη</HomeCardDescription>
              </HomeCard>
            </HomeButton>
          </Link>
          <Link to="/park-oversight">
            <HomeButton>
              <HomeCard>
                <HomeCardImage
                  //     src="
                  src={panelImg}
                  alt="Card2"
                />
                <HomeCardDescription>Λειτουργια Επιτηρησης</HomeCardDescription>
              </HomeCard>
            </HomeButton>
          </Link>
        </HomeCardsContainer>
      </HomeBody>
    </Layout>
  );
}
