import { handleApiError } from "../apiErrorHandling";

/**
 * Handles a successful retrieval of thermographies for a specific park from the API.
 * @param {Object} response - The Axios response object.
 * @param {Function} setThermographies - Function to set the thermographies in the component state.
 */
export const retrieveThermographiesSucc = (response, setThermographies) => {
  if (response.status === 200) {
    setThermographies(response.data);
  }
};

/**
 * Handles an error during the retrieval of thermographies for a specific park from the API.
 * @param {Object} error - The Axios error object.
 * @param {Function} setErrorMessage - Function to set an error message in the component state.
 * @param {Function} setIsErrorModalOpen - Function to control the error modal's visibility.
 */
export const retrieveThermographiesErr = (
  error,
  setErrorMessage,
  setIsErrorModalOpen
) => {
  handleApiError(error, setErrorMessage, setIsErrorModalOpen);
};

/**
 * Handles a successful retrieval of a specific thermography in a park from the API.
 * @param {Object} response - The Axios response object.
 * @param {Function} setThermography - Function to set the specific thermography data in the component state.
 */
export const retrieveThermographySucc = (response, setThermography) => {
  if (response.status === 200) {
    setThermography(response.data);
  }
};

/**
 * Handles an error during the retrieval of a specific thermography in a park from the API.
 * @param {Object} error - The Axios error object.
 * @param {Function} setErrorMessage - Function to set an error message in the component state.
 * @param {Function} setIsErrorModalOpen - Function to control the error modal's visibility.
 */
export const retrieveThermographyErr = (
  error,
  setErrorMessage,
  setIsErrorModalOpen
) => {
  handleApiError(error, setErrorMessage, setIsErrorModalOpen);
};

/**
 * Handles a successful retrieval of panels associated with a specific thermography in a park from the API.
 * @param {Object} response - The Axios response object.
 * @param {Function} setThermographyPanels - Function to set the panels associated with the thermography in the component state.
 */
export const retrieveThermographyPanelsSucc = (
  respone,
  setThermographyPanels
) => {
  if (respone.status === 200) {
    setThermographyPanels(respone.data);
  }
};

/**
 * Handles an error during the retrieval of panels associated with a specific thermography in a park from the API.
 * @param {Object} error - The Axios error object.
 * @param {Function} setErrorMessage - Function to set an error message in the component state.
 * @param {Function} setIsErrorModalOpen - Function to control the error modal's visibility.
 */
export const retrieveThermographyPanelsErr = (
  error,
  setErrorMessage,
  setIsErrorModalOpen
) => {
  handleApiError(error, setErrorMessage, setIsErrorModalOpen);
};

/**
 * Handles a successful retrieval of a specific panel associated with a thermography in a park from the API.
 * @param {Object} response - The Axios response object.
 * @param {Function} setThermographyPanel - Function to set the specific panel data associated with the thermography in the component state.
 */
export const retrieveThermographyPanelSucc = (
  respone,
  setThermographyPanel
) => {
  if (respone.status === 200) {
    setThermographyPanel(respone.data);
  }
};

/**
 * Handles an error during the retrieval of a specific panel associated with a thermography in a park from the API.
 * @param {Object} error - The Axios error object.
 * @param {Function} setErrorMessage - Function to set an error message in the component state.
 * @param {Function} setIsErrorModalOpen - Function to control the error modal's visibility.
 */
export const retrieveThermographyPanelErr = (
  error,
  setErrorMessage,
  setIsErrorModalOpen
) => {
  handleApiError(error, setErrorMessage, setIsErrorModalOpen);
};

/**
 * Handles a successful retrieval of meteorological data associated with a specific thermography in a park from the API.
 * @param {Object} response - The Axios response object.
 * @param {Function} setMeteorologicalData - Function to set the meteorological data associated with the thermography in the component state.
 */
export const retrieveMeteorologicalDataSucc = (
  response,
  setMeteorologicalData
) => {
  if (response.status === 200) {
    //console.log(response.data);
    setMeteorologicalData(response.data);
  }
};

/**
 * Handles an error during the retrieval of meteorological data associated with a specific thermography in a park from the API.
 * @param {Object} error - The Axios error object.
 * @param {Function} setErrorMessage - Function to set an error message in the component state.
 * @param {Function} setIsErrorModalOpen - Function to control the error modal's visibility.
 */
export const retrieveMeteorologicalDataErr = (
  error,
  setErrorMessage,
  setIsErrorModalOpen
) => {
  handleApiError(error, setErrorMessage, setIsErrorModalOpen);
};

export const retrieveTelemetryDataSucc = (
  response,
  setTelemetryData
) => {
  if (response.status === 200) {
    //console.log(response.data)
    setTelemetryData(response.data);
  }
};

export const retrieveTelemetryDataErr = (
  error,
  setErrorMessage,
  setIsErrorModalOpen
) => {
  handleApiError(error, setErrorMessage, setIsErrorModalOpen);
};