import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { retrievePanels } from "../../api/calls/panelCall";
import {
  retrievePanelsSucc,
  retrievePanelsErr,
} from "../../api/responses/panelResponse";

import ErrorModal from "../modal/ErrorModal";

import {
  InvertersTable,
  InvertersTableCell,
  MpptTableCell,
  PanelTableCell,
  ChevronDownIcon,
  ChevronRightIcon,
  MpptRow,
  PanelRow,
  PanelHeader,
} from "../../styles/page/parkComponents";

function MpptRowComponent({ mppt, index, inverterId }) {
  const { id: parkId } = useParams();
  const mpptId = mppt.id;
  const [panels, setPanels] = useState([]);

  const [isExpanded, setIsExpanded] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const handleClick = () => {
    setIsExpanded((prevExpanded) => !prevExpanded);

    if (!isExpanded) {
      fetchMpptsPanels(parkId, inverterId, mpptId);
    }
  };

  const fetchMpptsPanels = (parkId, inverterId, mpptId) => {
    retrievePanels(parkId, inverterId, mpptId)
      .then((response) => {
        retrievePanelsSucc(response, parkId, inverterId, mpptId, setPanels);
      })
      .catch((error) => {
        retrievePanelsErr(error, setErrorMessage, setIsErrorModalOpen);
      });
  };

  return (
    <>
      <ErrorModal
        className="modal"
        isOpen={isErrorModalOpen}
        onRequestClose={() => setIsErrorModalOpen(false)}
        errorMessage={errorMessage}
      />
      <InvertersTable>
        <MpptRow onClick={handleClick} isExpanded={isExpanded}>
          <MpptTableCell>{mppt.id}</MpptTableCell>
          <MpptTableCell></MpptTableCell>
          <MpptTableCell></MpptTableCell>
          <MpptTableCell></MpptTableCell>
          <MpptTableCell>
            {/* mppt row content */}
            {/* conditionally render ChevronDownIcon or ChevronRightIcon based on the expanded state */}
            {isExpanded ? <ChevronDownIcon /> : <ChevronRightIcon />}
          </MpptTableCell>
        </MpptRow>
        {isExpanded && (
          <>
            <MpptRow>
              <InvertersTableCell colSpan={5}>
                <br />
                <PanelHeader>Πλαίσια Στοιχειοσειράς {mppt.id}</PanelHeader>
              </InvertersTableCell>
            </MpptRow>

            {panels?.map((panel) => (
              <PanelRow key={panel.id} panel={panel}>
                <PanelTableCell>{panel.id}</PanelTableCell>
                <PanelTableCell>{panel.manufacturer}</PanelTableCell>
                <PanelTableCell>{panel.model}</PanelTableCell>
                <PanelTableCell>{panel.power}</PanelTableCell>
                <PanelTableCell></PanelTableCell>
              </PanelRow>
            ))}
            <br />
          </>
        )}
      </InvertersTable>
    </>
  );
}

export default MpptRowComponent;
