import { ParkInfo } from "../../../../components/park/ParkInfo";
import InverterRowComponent from "../../../../components/park/InverterRowComponent";

import {
  ParkPageContainer,
  ParkInfoContainer,
  ParkInfoButtonsContainer,
  DeleteParkInfoButtonContainer,
  EditParkInfoButtonsContainer,
  EditParkInfoButtonContainer,
  EditParkInfoInput,
  EditParkRadioContainer,
  EditParkRadioInput,
  EditParkRadioLabel,
  ParkInfoDeleteConfirmButton,
  ParkInfoDeleteCancelButton,
  InvertersTitle,
  InvertersTableContainer,
  InvertersTable,
  InvertersTableHeader,
  InvertersTableCell,
  InvertersTableRow,
} from "../../../../styles/page/parkComponents";

export const ParkDetailsTabContent = ({
  park,
  inverters,
  isEditing,
  editedPark,
  is_private,
  setPrivate,
  setEditedPark,
  handleSaveEdit,
  handleCancelEdit,
  handleEditPark,
  handleDeletePark,
}) => (
  <ParkPageContainer>
    <ParkInfoContainer>
      {isEditing ? (
        <>
          <EditParkInfoInput
            type="text"
            placeholder="Όνομα"
            value={editedPark.name}
            onChange={(e) =>
              setEditedPark({ ...editedPark, name: e.target.value })
            }
          />
          <EditParkInfoInput
            type="text"
            placeholder="Περιγραφή"
            value={editedPark.description}
            onChange={(e) =>
              setEditedPark({
                ...editedPark,
                description: e.target.value,
              })
            }
          />
          <EditParkRadioContainer>
            <EditParkRadioLabel>
              <EditParkRadioInput
                type="radio"
                id="privateTab"
                name="privateRadio"
                checked={is_private}
                onChange={() => setPrivate(true)}
              ></EditParkRadioInput>{" "}
              Ιδιωτικό
            </EditParkRadioLabel>
            <EditParkRadioLabel>
              <EditParkRadioInput
                type="radio"
                id="publicTab"
                name="privateRadio"
                checked={!is_private}
                onChange={() => setPrivate(false)}
              ></EditParkRadioInput>{" "}
              Δημόσιο
            </EditParkRadioLabel>
          </EditParkRadioContainer>
          <EditParkInfoButtonsContainer>
            <ParkInfoDeleteConfirmButton onClick={handleSaveEdit}>
              Αποθήκευση
            </ParkInfoDeleteConfirmButton>
            <ParkInfoDeleteCancelButton onClick={handleCancelEdit}>
              Ακύρωση
            </ParkInfoDeleteCancelButton>
          </EditParkInfoButtonsContainer>
        </>
      ) : (
        // Render the park info and edit button
        <>
          <ParkInfo park={park} />
          <ParkInfoButtonsContainer>
            <EditParkInfoButtonContainer
              title="Edit"
              onClick={handleEditPark}
            ></EditParkInfoButtonContainer>
            <DeleteParkInfoButtonContainer
              title="Delete"
              onClick={handleDeletePark}
            ></DeleteParkInfoButtonContainer>
          </ParkInfoButtonsContainer>
        </>
      )}
    </ParkInfoContainer>

    <InvertersTitle>Μετατροπείς</InvertersTitle>

    <InvertersTableContainer>
      <InvertersTable>
        {/* Table Header */}
        <InvertersTableHeader>
          <InvertersTableRow>
            <InvertersTableCell>Κωδικός</InvertersTableCell>
            <InvertersTableCell>Κατασκευαστής</InvertersTableCell>
            <InvertersTableCell>Μοντέλο</InvertersTableCell>
            <InvertersTableCell>Ισχύς</InvertersTableCell>
            {/* Empty cell for arrow button */}
            <InvertersTableCell></InvertersTableCell>{" "}
          </InvertersTableRow>
        </InvertersTableHeader>

        <InvertersTableRow>
          <InvertersTableCell colSpan={5}>
            {inverters.map((inverter, index) => (
              <InverterRowComponent
                key={inverter.id}
                inverter={inverter}
                index={index}
              />
            ))}
          </InvertersTableCell>
        </InvertersTableRow>
      </InvertersTable>
    </InvertersTableContainer>
  </ParkPageContainer>
);
