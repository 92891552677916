import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { FiChevronDown, FiChevronRight } from "react-icons/fi";

import {
  BaseContainer,
  PageContainer,
  CreatePlaceBody,
  CreatePlaceContainer,
  CreatePlaceTitle,
  CreatePlaceForm,
  CreatePlaceInput,
  PlacesInfoRadioContainer,
  PlacesInfoRadioInput,
  PlacesInfoRadioLabel,
  EditPlacesInfoRadioContainer,
  EditPlacesInfoRadioInput,
  EditPlacesInfoRadioLabel,
  CreatePlaceButton,
  PlaceTableContainer,
  BaseTableContainer,
  PlaceTable,
  BaseTableBody,
  PlaceTableRow,
  PlaceTableHeader,
  PlaceTableCell,
  PlaceDateCell,
  PlaceButtonRow,
  BaseTitle,
  PlaceInfoContainer,
  PlaceInfoBackground,
  PlaceInfoWrapper,
  PlaceInfoID,
  PlaceInfoDescription,
  ModeTitle,
  DownloadFilesButton,
  DownloadFilesImageButton,
  BaseInput,
  PlaceButtonsContainer,
  PlaceEditButtonContainer,
  PlaceDeleteButtonContainer,
  PlaceDeleteConfirmButton,
  PlaceDeleteCancelButton,
  BaseTableHeader,
  BaseTableCell,
  BaseTableRow,
  BaseTableThead,
  BaseH3,
  BaseTable,
  BaseParagraph,
  BaseButton,
} from "../sharedStyles";

export const ParkPageContainer = styled(PageContainer)`
  margin-top: 50px;
`;

export const CreateParkBody = styled(CreatePlaceBody)``;

export const CreateParkContainer = styled(CreatePlaceContainer)``;

export const CreateParkTitle = styled(CreatePlaceTitle)``;

export const CreateParkForm = styled(CreatePlaceForm)``;

export const CreateParkInput = styled(CreatePlaceInput)``;

export const CreateParkRadioContainer = styled(PlacesInfoRadioContainer)``;

export const CreateParkRadioInput = styled(PlacesInfoRadioInput)``;

export const CreateParkRadioLabel = styled(PlacesInfoRadioLabel)``;

export const EditParkRadioContainer = styled(EditPlacesInfoRadioContainer)``;

export const EditParkRadioInput = styled(EditPlacesInfoRadioInput)``;

export const EditParkRadioLabel = styled(EditPlacesInfoRadioLabel)``;

export const CreateParkButton = styled(CreatePlaceButton)``;

export const CreateParkStyledLink = styled(Link)`
  color: #274a1c;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const ParkTableContainer = styled(PlaceTableContainer)`
  max-width: 1200px;
`;

export const ParkTable = styled(PlaceTable)`
  border-collapse: collapse;
`;

export const ParkTableBody = styled(BaseTableBody)``;

export const ParkTableRow = styled(PlaceTableRow)``;

export const ParkTableHeader = styled(PlaceTableHeader)``;

export const ParkTableCell = styled(PlaceTableCell)``;

export const ParkDateCell = styled(PlaceDateCell)``;

export const ParkButtonRow = styled(PlaceButtonRow)``;

export const ParkTitle = styled(BaseTitle)`
  font-size: 25px;
  margin: 40px 0;
  width: 100%;
`;

export const ParkInfoContainer = styled(PlaceInfoContainer)``;

export const ParkInfoBackground = styled(PlaceInfoBackground)``;

export const ParkInfoWrapper = styled(PlaceInfoWrapper)``;

export const ParkInfoID = styled(PlaceInfoID)``;

export const ParkInfoDescription = styled(PlaceInfoDescription)`
  width: 90%;
  max-width: 90%;
`;

export const OversightTitle = styled(ModeTitle)``;

export const EditParkInfoInput = styled(BaseInput)`
  margin: 8px 8px 8px 8px;
  width: 100%;
`;

export const ParkInfoButtonsContainer = styled(PlaceButtonsContainer)``;
export const EditParkInfoButtonsContainer = styled(PlaceButtonsContainer)``;

export const EditParkInfoButtonContainer = styled(PlaceEditButtonContainer)``;

export const DeleteParkInfoButtonContainer = styled(
  PlaceDeleteButtonContainer
)``;

export const ParkInfoDeleteConfirmButton = styled(PlaceDeleteConfirmButton)``;

export const ParkInfoDeleteCancelButton = styled(PlaceDeleteCancelButton)``;

export const InvertersTableContainer = styled(PlaceTableContainer)`
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
`;

export const InvertersTable = styled(PlaceTable)`
  border-collapse: collapse;
`;

export const BaseParkHeader = styled.thead`
  background: #b8d669;
  color: #fff;
  padding: 15px;
  text-align: center;
  font-weight: bold;
  height: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

export const InvertersTableHeader = styled(BaseParkHeader)`
  font-size: 20px;
`;

export const InvertersTableCell = styled(BaseTableCell)`
  padding: 10px;

  /* Set fixed width for each cell */
  &:nth-child(1) {
    /* First cell (ID) */
    width: 15%;
  }

  &:nth-child(2) {
    /* Second cell (Manufacturer) */
    width: 30%;
  }

  &:nth-child(3) {
    /* Third cell (Model) */
    width: 30%;
  }
  &:nth-child(4) {
    /* Fourth cell (Power) */
    width: 10%;
  }
  &:nth-child(5) {
    /* Sixth cell (Arrow) */
    width: 15%;
  }
`;

export const InvertersTableRow = styled(BaseTableRow)``;

export const InvertersTitle = styled(BaseTitle)`
  font-size: 22px;
  color: #394d3b;
  margin: 40px 0;
`;

export const InverterTableCell = styled(InvertersTableCell)``;

export const MpptTableCell = styled(InvertersTableCell)``;

export const PanelTableCell = styled(InvertersTableCell)``;

export const InverterRow = styled(BaseTableRow)`
  background-color: rgba(255, 255, 255, 0.8);
  /* Additional styles for expanded rows */
  ${(props) =>
    props.isExpanded &&
    css`
      background-color: #e6ffac;
      color: #000; /* Text color for the expanded row */
    `}
`;

export const MpptRow = styled(BaseTableRow)`
  background-color: #fafef6;
  /* Additional styles for expanded rows */
  ${(props) =>
    props.isExpanded &&
    css`
      background-color: #f5f5af;
      color: #000;
    `}
`;
export const PanelRow = styled(BaseTableRow)`
  background-color: #fefee8;
`;

export const MpptHeader = styled(BaseH3)`
  background-color: #f5ffe0;
  font-size: 1.2em;
`;

export const PanelHeader = styled(BaseH3)`
  background-color: #edeccf;
  font-size: 1.1em;
`;

export const ChevronDownIcon = styled(FiChevronDown)`
  color: #000;
  font-size: 24px;
  cursor: pointer;
`;

export const ChevronRightIcon = styled(FiChevronRight)`
  color: #999;
  font-size: 24px;
  cursor: pointer;
`;

export const ThermographyTableContainer = styled(BaseTableContainer)`
  width: 90%;
  max-width: 1200px;
  border-radius: 5px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
`;

export const ThermographyTable = styled(BaseTable)``;

export const ThermographyTableBody = styled(BaseTableBody)``;

export const ThermographyTableRow = styled(PlaceTableRow)``;

export const ThermographyTableHeader = styled(PlaceTableHeader)`
  border-radius: 5px;
`;

export const ThermographyTableCell = styled(PlaceTableCell)`
  background-color: #fff;
  border-radius: 5px;
`;

export const ThermographyDateCell = styled(PlaceDateCell)`
  background-color: #fff;
  border-radius: 5px;
`;

export const ThermographyButtonRow = styled(PlaceButtonRow)``;

export const ThermographyDownloadButton = styled(DownloadFilesButton)``;

export const ThermographyDownloadImg = styled(DownloadFilesImageButton)``;

export const ThermographyImageContainer = styled.div`
  border-radius: 10px;
  width: 30vw; /* 25% of the viewport width */
  height: 40vh; /*  50% of the viewport height */
`;

export const ThermographyImage = styled.img`
  border-radius: 10px;
  width: 100%;
  height: 100%;
`;

export const ThermographyPanelsTableContainer = styled(BaseTableContainer)`
  width: 85%;
  margin-top: 20px;
  border-radius: 10px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
`;

export const ThermographyPanelsTable = styled(BaseTable)`
  border-collapse: collapse;
`;

export const ThermographyPanelsTableHeader = styled(BaseParkHeader)`
  font-size: 18px;
`;

export const ThermographyPanelsTableCell = styled(BaseTableCell)`
  padding: 10px;
  /* Set fixed width for each cell */
  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(4),
  &:nth-child(5) {
    width: 20%;
  }
`;
export const ThermographyPanelsTableRow = styled(BaseTableRow)`
  /* Set fixed width for each cell */
  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(4),
  &:nth-child(5) {
    width: 20%;
  }
`;

export const ThermographyPanelsTitle = styled(BaseTitle)`
  font-size: 24px;
  margin: 70px 40px 0;
  color: #394d3b;
`;

export const ThermographyPanelImageContainer = styled(BaseContainer)`
  justify-content: center;
  border-radius: 10px;
  width: 100%; /* Occupy the entire cell width */
  height: 100%; /* Occupy the entire cell height */
  overflow: hidden;
`;

export const ThermographyPanelImage = styled.img`
  border-radius: 10px;
  width: auto; /* image's width adjust according to its aspect ratio */
  height: auto; /* image's height adjust according to its aspect ratio */
  max-width: 20%;
  max-height: 20%;
  vertical-align: middle; /* Reset vertical alignment */
`;

export const ThermographyImageAndReportsContainer = styled(BaseContainer)`
  justify-content: space-between;
  gap: 50px;
  margin-right: 100px;
  margin-left: 100px;
`;

export const ThermographyReportContainer = styled(BaseContainer)`
  justify-content: space-between; /* Add space between children */
  font-family: inherit;
  font-size: 18px;
  position: relative;
  gap: 10px;
  padding: 10px;
  margin-right: 105px;
`;

export const ThermographyInfoContainer = styled(BaseContainer)`
  width: 50vw; /* 60% of the viewport width */
  height: 40vh; /* 30% of the viewport height */
  justify-content: space-between;
  border-radius: 10px;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8); /* Very light white */
  padding: 20px; /* Space inside the box */
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
`;

export const ThermographyInfoBackground = styled(PlaceInfoBackground)``;

export const ThermographyInfoWrapperContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto; /* Center the container horizontally */
`;

export const ThermographyInfoWrapper = styled(PlaceInfoWrapper)``;

export const ThermographyID = styled.p`
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
  margin-bottom: 20px;
`;

export const ThermographyDescription = styled(BaseParagraph)`
  font-size: 18px;
  font-weight: 100;
  line-height: 20px;
`;

export const MeteorologyWrapper = styled(BaseContainer)`
  width: 1200px;
  justify-content: center;
  background-color: #fff;
  padding-left: 50px;
  padding-right: 10px;
  padding-top: 50px;
  padding-bottom: 20px;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;

export const MeteorologyChartContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const MeteorologyDivContainer = styled.div`
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
`;

export const NoMeteorologyDataParagraph = styled(BaseParagraph)`
  font-size: 18px;
  align-items: center;
  justify-content: center;
  margin-top: 3%;
  margin-bottom: 3%;
`;

export const TelemetryParkTotalContainer = styled.div`
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center; /* Center the content vertically */
  padding: 15px;
`;

export const TelemetryEnergyContainer = styled.div`
  background-color: #fff;
  padding: 15px;
  flex: 1; /* Expand to fill available space */
  margin-right: 15px; /* margin between containers */
  margin-left: 35px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
`;

export const TelemetryPowerContainer = styled.div`
  background-color: #fff;
  padding: 15px;
  flex: 1; /* Expand to fill available space */
  margin-left: 35px; /* margin between containers */
  margin-right: 30px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
`;

export const TelemetryParkTotalParagraph = styled(BaseParagraph)`
  width: 100%;
  font-family: inherit;
  font-size: 15px;
  color: #1a4314;
  text-align: center; /* Center the text horizontally */
`;

export const TelemetryChartContainer = styled.div`
  width: 90%;
  height: 90%;
  justify-content: space-between;
`;

export const TelemetryPhraseWrapper = styled.div`
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  font-family: inherit;
  letter-spacing: 0.1px;
  gap: 0px;
  font-size: 15px;
`;

export const TelemetryChartContainerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: 40px;
  flex-direction: row;
  justify-content: space-between;
  gap: 50px;
`;

export const TelemetryInverterChartContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-right: 0px; /* margin between containers */
  margin-left: 0px;
  border-radius: 10px;
`;


export const TelemetryMpptChartContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-left: 0px; /* margin between containers */
  margin-right: 0px;
  border-radius: 10px;
`;

export const TelemetryBottomContainer = styled.div`
  width: 95%;
  display: flex;
  padding: 15px;
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

export const TelemetryMpptToggleContainer = styled.div`
  background-color: #fff;
  padding: 15px;
  flex: 1; /* Expand to fill available space */
  margin-left: 35px; /* margin between containers */
  margin-right: 30px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
`;


export const TelemetryToggleButton = styled(BaseButton)`
  display: flex; /* Use flexbox */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  background-color: ${(props) => (props.toggle ? "#bad568" : "#d1c55a")};
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  white-space: nowrap;
  font-size: 17px;
  letter-spacing: 10px;

  /* Match the width and height of the MpptToggleContainer */
  width: 100%;
  height: 100%;
`;

export const TelemetryInverterTableContainer = styled.div`
  padding: 15px;
  flex: 1; /* Expand to fill available space */
  flex-grow: 1;
  flex-shrink: 1;
  margin-right: 15px; /* margin between containers */
  margin-left: 35px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
`;

export const TelemetryInverterPowerTable = styled(BaseTable)`
  border-collapse: collapse;
`;

export const TelemetryInverterPowerRow = styled(TelemetryInverterPowerTable)`
  background-color: rgba(255, 255, 255, 0.8);
  ${(props) =>
    props.isExpanded &&
    css`
      background-color: #e6ffac;
      color: #000; /* Text color for the expanded row */
    `}
`;

export const TelemetryInverterPowerTableCell = styled.td`
  font-size: 15px;
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center; /* Center the text */
`;

export const TelemetryDivContainer = styled(MeteorologyDivContainer)``;


export const NoTelemetryDataParagraph = styled(NoMeteorologyDataParagraph)``;

