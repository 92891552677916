import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { retrieveMppts } from "../../api/calls/mpptCall";
import { deleteInverter } from "../../api/calls/inverterCall";
import {
  retrieveMpptsSucc,
  retrieveMpptsErr,
} from "../../api/responses/mpptResponse";
import {
  deleteInverterSucc,
  deleteInverterErr,
} from "../../api/responses/inverterResponse";

import MpptRowComponent from "./MpptRowComponent";

import ErrorModal from "../modal/ErrorModal";

import {
  InvertersTable,
  InverterRow,
  InvertersTableCell,
  InverterTableCell,
  MpptHeader,
  ChevronDownIcon,
  ChevronRightIcon,
} from "../../styles/page/parkComponents";

function InverterRowComponent({ inverter, index }) {
  const { id: parkId } = useParams();
  const inverterId = inverter.id;

  const [mppts, setMppts] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const handleClick = () => {
    setIsExpanded((prevExpanded) => !prevExpanded); // Toggle the isExpanded state

    if (!isExpanded) {
      fetchInvertersMppts(parkId, inverterId);
    }
  };

  const fetchInvertersMppts = (parkId, inverterId) => {
    retrieveMppts(parkId, inverterId)
      .then((response) => {
        retrieveMpptsSucc(response, parkId, inverterId, setMppts);
      })
      .catch((error) => {
        retrieveMpptsErr(error, setErrorMessage, setIsErrorModalOpen);
      });
  };

  const handleDeleteInverter = (e) => {
    e.preventDefault();

    deleteInverter(parkId, inverterId)
      .then((response) => {
        deleteInverterSucc(response);
      })
      .catch((error) => {
        deleteInverterErr(error, setErrorMessage, setIsErrorModalOpen);
      });
  };

  return (
    <>
      <ErrorModal
        className="modal"
        isOpen={isErrorModalOpen}
        onRequestClose={() => setIsErrorModalOpen(false)}
        errorMessage={errorMessage}
      />
      <InvertersTable>
        <InverterRow onClick={handleClick} isExpanded={isExpanded}>
          <InverterTableCell>{inverter.id}</InverterTableCell>
          <InverterTableCell>{inverter.manufacturer}</InverterTableCell>
          <InverterTableCell>{inverter.model}</InverterTableCell>
          <InverterTableCell>{inverter.power}</InverterTableCell>
          <InverterTableCell>
            {/* inverter row content */}
            {/* conditionally render ChevronDownIcon or ChevronRightIcon based on the expanded state */}
            {isExpanded ? <ChevronDownIcon /> : <ChevronRightIcon />}
          </InverterTableCell>
        </InverterRow>
        {isExpanded && (
          <>
            <InverterRow>
              <InvertersTableCell colSpan={5}>
                <br />
                <MpptHeader>Στοιχειοσειρές Μετατροπέα {inverter.id}</MpptHeader>
              </InvertersTableCell>
            </InverterRow>

            <InverterRow>
              <InverterTableCell colSpan={5}>
                {mppts.map((mppt, index) => (
                  <MpptRowComponent
                    key={mppt.id}
                    mppt={mppt}
                    inverterId={inverter.id}
                  />
                ))}
              </InverterTableCell>
            </InverterRow>
          </>
        )}
      </InvertersTable>
    </>
  );
}

export default InverterRowComponent;
