import React, { useEffect, useState } from "react";

import ImageModal from "../../../../../components/modal/ImageModal";
import LoadingSpinner from "../../../../../utils/LoadingSpinner";

import {
  retrieveThermography,
  retrieveThermographyPanels,
} from "../../../.././../api/calls/thermographyCall";
import {
  retrieveThermographyErr,
  retrieveThermographyPanelsErr,
  retrieveThermographyPanelsSucc,
  retrieveThermographySucc,
} from "../../../.././../api/responses/thermographyResponse";

import {
  ParkPageContainer,
  ThermographyImageAndReportsContainer,
  ThermographyReportContainer,
  ThermographyInfoContainer,
  ThermographyInfoWrapper,
  ThermographyDownloadButton,
  ThermographyDownloadImg,
  ThermographyImageContainer,
  ThermographyImage,
  ThermographyInfoBackground,
  ThermographyPanelsTitle,
  ThermographyPanelsTableContainer,
  ThermographyPanelsTable,
  ThermographyPanelsTableHeader,
  ThermographyPanelsTableCell,
  ThermographyPanelsTableRow,
  ThermographyPanelImageContainer,
  ThermographyPanelImage,
  ThermographyID,
  ThermographyDescription,
} from "../../../../../styles/page/parkComponents";

import downloadPDF from "../../../../../assets/download.png";
import ErrorModal from "../../../../../components/modal/ErrorModal";

export const ThermographyDetailsTabContent = ({ parkId, thermographyId }) => {
  const [thermographyData, setThermographyData] = useState({});
  const [thermographyPanels, setThermographyPanels] = useState([]);

  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [isLoadingImage, setIsLoadingImage] = useState(false);

  useEffect(() => {
    if (parkId && thermographyId) {
      fetchThermographyData(parkId, thermographyId);
      fetchThermographyPanels(parkId, thermographyId);
    }
  }, [parkId, thermographyId]);

  const fetchThermographyData = (parkId, thermographyId) => {
    setIsLoading(true);
    retrieveThermography(parkId, thermographyId)
      .then((response) => {
        retrieveThermographySucc(response, setThermographyData);
      })
      .catch((error) => {
        // Handle any errors
        retrieveThermographyErr(error, setErrorMessage, setIsErrorModalOpen);
      })
      .finally(() => {
        setIsLoading(false); // Set loading to false when the request completes
      });
  };

  const fetchThermographyPanels = (parkId, thermographyId) => {
    setIsLoading(true);
    retrieveThermographyPanels(parkId, thermographyId)
      .then((response) => {
        retrieveThermographyPanelsSucc(response, setThermographyPanels);
      })
      .catch((error) => {
        retrieveThermographyPanelsErr(
          error,
          setErrorMessage,
          setIsErrorModalOpen
        );
      })
      .finally(() => {
        setIsLoading(false); // Set loading to false when the request completes
      });
  };

  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setModalIsOpen(false);
  };

  const downloadErrorProbabilityReport = () => {
    if (thermographyData.error_probability_report) {
      window.open(thermographyData.error_probability_report, "_blank");
    }
  };

  const downloadSpecializedErrorReport = () => {
    if (thermographyData.specialized_error_report) {
      window.open(thermographyData.specialized_error_report, "_blank");
    }
  };

  const sortedThermographyPanels = thermographyPanels
    .slice()
    .sort((a, b) => a.panel_id - b.panel_id);

  if (isLoading) {
    // Display loading spinner while fetching data
    return (
      <ParkPageContainer>
        <LoadingSpinner />
      </ParkPageContainer>
    );
  }

  return (
    <ParkPageContainer>
      <ErrorModal
        className="modal"
        isOpen={isErrorModalOpen}
        onRequestClose={() => setIsErrorModalOpen(false)}
        errorMessage={errorMessage}
      />
      <ThermographyImageAndReportsContainer>
        <ThermographyImageContainer>
          {isLoadingImage ? (
            // Display loading spinner while the image is loading
            <LoadingSpinner />
          ) : (
            <ThermographyImage
              src={thermographyData.thermography_image_park}
              alt="Thermography Image"
              onClick={() =>
                openModal(thermographyData.thermography_image_park)
              }
              onLoad={() => setIsLoadingImage(false)} // Image is loaded, set isLoadingImage to false
              onError={() => setIsLoadingImage(false)} // Image failed to load, set isLoadingImage to false
            />
          )}
        </ThermographyImageContainer>

        <ThermographyInfoContainer>
          <ThermographyInfoBackground>
            <ThermographyInfoWrapper>
              <ThermographyID>
                Κωδικός θερμογραφίας: {thermographyData.id}
              </ThermographyID>
              <ThermographyDescription>
                Περιγραφή: {thermographyData.description}
              </ThermographyDescription>

              <br />
              <br />
              <br />

              <ThermographyID>Αναφορές σφαλμάτων</ThermographyID>
              <ThermographyReportContainer>
                <ThermographyDescription
                  style={{ marginBottom: 5, color: "#394d3b" }}
                >
                  ◆
                </ThermographyDescription>
                <ThermographyDescription>
                  Αναφορά πιθανότητας σφάλματος
                </ThermographyDescription>
                <ThermographyDownloadButton
                  onClick={downloadErrorProbabilityReport}
                >
                  <ThermographyDownloadImg src={downloadPDF} alt="Download" />
                </ThermographyDownloadButton>
              </ThermographyReportContainer>

              <ThermographyReportContainer>
                <ThermographyDescription
                  style={{ marginBottom: 5, color: "#394d3b" }}
                >
                  ◆
                </ThermographyDescription>
                <ThermographyDescription>
                  Αναφορά εξειδίκευσης σφάλματος
                </ThermographyDescription>
                <ThermographyDownloadButton
                  onClick={downloadSpecializedErrorReport}
                >
                  <ThermographyDownloadImg src={downloadPDF} alt="Download" />
                </ThermographyDownloadButton>
              </ThermographyReportContainer>
            </ThermographyInfoWrapper>
          </ThermographyInfoBackground>
        </ThermographyInfoContainer>
      </ThermographyImageAndReportsContainer>

      <ThermographyPanelsTitle>Ελαττωματικά πλαίσια</ThermographyPanelsTitle>

      <ThermographyPanelsTableContainer>
        {sortedThermographyPanels && sortedThermographyPanels.length > 0 ? (
          <ThermographyPanelsTable>
            <ThermographyPanelsTableHeader>
              <ThermographyPanelsTableCell>
                Κωδικός πλαισίου
              </ThermographyPanelsTableCell>
              <ThermographyPanelsTableCell>
                Πιθανό σφάλμα
              </ThermographyPanelsTableCell>
              <ThermographyPanelsTableCell>
                Διαφορά θερμοκρασίας
              </ThermographyPanelsTableCell>
              <ThermographyPanelsTableCell>
                Θερμική εικόνα
              </ThermographyPanelsTableCell>{" "}
              <ThermographyPanelsTableCell>
                Σύγκριση με σύνολο εκπαίδευσης
              </ThermographyPanelsTableCell>
            </ThermographyPanelsTableHeader>

            {sortedThermographyPanels.map((panel) => (
              <ThermographyPanelsTableRow colSpan={5}>
                <ThermographyPanelsTableCell>
                  {panel.panel_id}
                </ThermographyPanelsTableCell>
                <ThermographyPanelsTableCell>
                  {panel.possible_error
                    ? `${panel.possible_error}`
                    : "Μη διαθέσιμο"}
                </ThermographyPanelsTableCell>
                <ThermographyPanelsTableCell>
                  {panel.temperature_difference
                    ? `${panel.temperature_difference}`
                    : "Μη διαθέσιμη"}
                </ThermographyPanelsTableCell>
                <ThermographyPanelsTableCell>
                  <ThermographyPanelImageContainer>
                    <ThermographyPanelImage
                      src={panel.thermal_image_faulty_panel}
                      alt="Panel Image Before"
                      onClick={() =>
                        openModal(panel.thermal_image_faulty_panel)
                      }
                    />
                  </ThermographyPanelImageContainer>
                </ThermographyPanelsTableCell>
                <ThermographyPanelsTableCell>
                  <ThermographyPanelImageContainer>
                    <ThermographyPanelImage
                      src={panel.image_comparison_from_training_set}
                      alt="Panel Image After"
                      onClick={() =>
                        openModal(panel.image_comparison_from_training_set)
                      }
                    />
                  </ThermographyPanelImageContainer>
                </ThermographyPanelsTableCell>
              </ThermographyPanelsTableRow>
            ))}
          </ThermographyPanelsTable>
        ) : (
          // Render a message when no panels are found
          <div>
            <br />
            <br />
            <p>Δεν βρέθηκαν ελαττωματικά πλαίσια.</p>
            <br />
            <br />
          </div>
        )}
      </ThermographyPanelsTableContainer>

      <ImageModal
        imageUrl={selectedImage}
        onClose={closeModal}
        isOpen={modalIsOpen}
      />
    </ParkPageContainer>
  );
};
