import React, { useState } from "react";

import { TabButton, TabButtons, TabContent, TabsContainer } from "../styles/components/tabsComponents";


const Tabs = ({ children }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <TabsContainer>
      <TabButtons>
        {children.map((child, index) => (
          <TabButton
            key={index}
            onClick={() => handleTabClick(index)}
            active={index === activeTab}
          >
            {child.props.title}
          </TabButton>
        ))}
      </TabButtons>
      <TabContent>{children[activeTab]}</TabContent>
    </TabsContainer>
  );
};

export default Tabs;
