import React, { useState, useEffect } from "react";
import "chartjs-adapter-moment";
import moment from "moment-timezone";

import { retrieveMeteorologicalData } from "../../../../../api/calls/thermographyCall";
import {
  retrieveMeteorologicalDataErr,
  retrieveMeteorologicalDataSucc,
} from "../../../../../api/responses/thermographyResponse";
import { meteorologyChartDatasets } from "../chart/chartDatasets";
import { meteorologyChartOptions } from "../chart/chartOptions";
import ErrorModal from "../../../../../components/modal/ErrorModal";
import LoadingSpinner from "../../../../../utils/LoadingSpinner";
import { Line } from "react-chartjs-2";

import {
  ParkPageContainer,
  MeteorologyWrapper,
  MeteorologyChartContainer,
  MeteorologyDivContainer,
  NoMeteorologyDataParagraph
} from "../../../../../styles/page/parkComponents";

export const MeteorologyDetailsTabContent = ({ parkId, thermographyId }) => {
  const [meteorologyData, setMeteorologyData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (parkId && thermographyId) {
      setIsLoading(true);
      retrieveMeteorologicalData(parkId, thermographyId)
        .then((response) => {
          retrieveMeteorologicalDataSucc(response, setMeteorologyData);
        })
        .catch((error) => {
          retrieveMeteorologicalDataErr(
            error,
            setErrorMessage,
            setIsErrorModalOpen
          );
        })
        .finally(() => {
          setIsLoading(false); // Set loading to false when the request completes
        });
    }
  }, [parkId, thermographyId]);

  if (isLoading) {
    // Display loading spinner while fetching data
    return (
      <ParkPageContainer>
        <LoadingSpinner />
      </ParkPageContainer>
    );
  }

  if (!meteorologyData || Object.keys(meteorologyData).length === 0) {
    return (
      <ParkPageContainer>
        <MeteorologyDivContainer>
          <NoMeteorologyDataParagraph>
            Δεν βρέθηκαν μετεωρολογικά δεδομένα.
          </NoMeteorologyDataParagraph>
        </MeteorologyDivContainer>
      </ParkPageContainer>
    );
  }

  return (
    <ParkPageContainer>
      <ErrorModal
        className="modal"
        isOpen={isErrorModalOpen}
        onRequestClose={() => setIsErrorModalOpen(false)}
        errorMessage={errorMessage}
      />
      <MeteorologyWrapper>
        <MeteorologyChartContainer>
          <Line
            options={meteorologyChartOptions}
            data={{
              labels: meteorologyData.map((item) => {
                // Convert UTC timestamp to local time using moment-timezone
                const localTime = moment.utc(item.timestamp).local(true);
                return localTime.format();
              }),
              datasets: meteorologyChartDatasets(meteorologyData),
            }}
          />
        </MeteorologyChartContainer>
      </MeteorologyWrapper>
    </ParkPageContainer>
  );
};
