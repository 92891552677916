import "moment-timezone";
import moment from "moment-timezone";

import {
  Chart as ChartJS,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";

moment.tz.setDefault("Europe/Athens");

const commonOptions = {
  responsive: true,
  scales: {
    x: {
      type: "time",
      time: {
        unit: "minute",
      },
    },
  },
  plugins: {
    zoom: {
      pan: {
        enabled: true,
        mode: "x",
        speed: 10,
      },
      zoom: {
        wheel: {
          enabled: true,
          modifiers: { x: "x", y: false },
        },
        pinch: {
          enabled: true,
        },
        mode: "x",
      },
    },
  },
};

function generateChartOptions(title, labelCallback, fontSize = 16) {
  return {
    ...commonOptions,
    plugins: {
      ...commonOptions.plugins,
      title: {
        display: true,
        text: title,
        fontSize: fontSize,
        padding: {
          top: 10,
          bottom: 10,
        },
      },
      tooltip: {
        callbacks: {
          label: labelCallback,
        },
        enabled: true,
        mode: "nearest",
        intersect: false,
      },
    },
  };
}

export const meteorologyChartOptions = generateChartOptions(
  "Μετεωρολογικά Δεδομένα",
  (context) => {
    const label = context.dataset.label || "";
    const value = context.parsed.y;
    const unit = context.dataset.unit || "";
    return `${label}: ${value} ${unit}`;
  }
);

export const optionsInverterEnergy = generateChartOptions(
  "Ημερήσια Ενέργεια Μετατροπέα",
  function (context) {
    let label = `Μετατροπέας - ${context.dataset.label} | ` || "";

    if (context.parsed.y !== null) {
      label += `Ισχύς: ${context.parsed.y} kWh | `;
    }
    if (context.dataset.power) {
      label += `Μέγιστη Ισχύς: ${context.dataset.power} kW`;
    }
    return label;
  }
);

export const optionsMpptVoltage = generateChartOptions(
  "Ημερήσια Τάση Στοιχειοσειράς",
  (context) => {
    let label = `Στοιχειοσειρά - ${context.dataset.label} | ` || "";

    if (context.parsed.y !== null) {
      label += `Τάση: ${context.parsed.y} V `;
    }
    return label;
  }
);

export const optionsMpptEnergy = generateChartOptions(
  "Ημερήσια Ισχύς Στοιχειοσειράς",
  (context) => {
    let label = `Στοιχειοσειρά - ${context.dataset.label} | ` || "";

    if (context.parsed.y !== null) {
      label += `Ισχύς: ${context.parsed.y} kWh `;
    }
    return label;
  },
  20
);

ChartJS.register(
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);
