import React from "react";
import ReactModal from "react-modal";

import {
  ImageModalOverlay,
  ImageModalContent,
  ImageModalImage,
  ImageModalCloseButton,
} from "../../styles/modals/imageModalComponents";

function ImageModal({ imageUrl, onClose }) {
  return (
    <ReactModal
      isOpen={imageUrl !== null}
      onRequestClose={onClose}
      ariaHideApp={false}
      style={{
        overlay: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        content: {
          background: "none",
          border: "none",
          padding: "0",
          maxWidth: "80vw",
          maxHeight: "80vh",
        },
      }}
    >
      <ImageModalOverlay>
        <ImageModalContent>
          <ImageModalImage src={imageUrl} alt="Popup" />
          <ImageModalCloseButton onClick={onClose}></ImageModalCloseButton>
        </ImageModalContent>
      </ImageModalOverlay>
    </ReactModal>
  );
}

export default ImageModal;
