import axios from "axios";

/**
 * Function to handle user login.
 * @param {string} email - The email.
 * @param {string} pwd - The password.
 * @returns {Promise} - Axios promise object representing the POST request.
 */
export const login = (email, pwd) => {
  // Create form data to send in the request
  const formData = new FormData();
  formData.append("client_id", process.env.REACT_APP_CLIENT_ID);
  formData.append("username", email);
  formData.append("password", pwd);

  // Make a POST request to the login endpoint
  return axios.post("auth/user/token", formData);
};