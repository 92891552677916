import React from "react";

import {ParkInfoBackground, ParkInfoWrapper, ParkInfoID, ParkInfoDescription } from "../../styles/page/parkComponents"

export const ParkInfo = ({ park }) => {
  return (
    <ParkInfoBackground>
      <ParkInfoWrapper>
        <ParkInfoID>Κωδικός: {park.id}</ParkInfoID>
        <ParkInfoDescription>Περιγραφή: {park.description}</ParkInfoDescription>
      </ParkInfoWrapper>
    </ParkInfoBackground>
  );
};
