import axios from "axios";

/**
 * Function to retrieve a list of MPPTs (Maximum Power Point Trackers) for a specific inverter in a park.
 * @param {string} parkId - The ID of the park containing the inverter.
 * @param {string} inverterId - The ID of the inverter containing the MPPTs.
 * @returns {Promise} - Axios promise object representing the GET request.
 */
export const retrieveMppts = (parkId, inverterId) => {
  return axios.get(`places/parks/${parkId}/inverters/${inverterId}/mppts`);
};
