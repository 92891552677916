import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import UserAuthorization from "../../../authorization/UserAuthorization";

import { createArea, retrieveAreas } from "../../../api/calls/areaCall";
import {
  createAreaSucc,
  createAreaErr,
} from "../../../api/responses/areaResponse";

import Layout from "../../../utils/Layout";
import LoadingSpinner from "../../../utils/LoadingSpinner";
import ErrorModal from "../../../components/modal/ErrorModal";

import {
  AreaPageContainer,
  CreateAreaBody,
  CreateAreaContainer,
  CreateAreaTitle,
  CreateAreaForm,
  CreateAreaInput,
  CreateAreaRadioContainer,
  CreateAreaRadioInput,
  CreateAreaRadioLabel,
  CreateAreaButton
} from "../../../styles/page/areaComponents";

export default function CreateArea() {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const [demRes, setDemRes] = useState(null);
  const [orthoRes, setOrthoRes] = useState(null);

  const [privacy, setPrivacy] = useState("public");
  const [selectedFile, setSelectedFile] = useState(null);

  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const handlePrivacyChange = (event) => {
    setPrivacy(event.target.value);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleCreateArea = (e) => {
    e.preventDefault();

    if (!UserAuthorization.isAuthenticated()) {
      navigate("/welcome");
    } else {
      checkAreaNameExists(name)
        .then((isAreaNameExists) => {
          if (isAreaNameExists) {
            setErrorMessage(
              "Το όνομα του οικοπέδου υπάρχει ήδη. Παρακαλώ, καταχωρήστε διαφορετικό όνομα για το οικόπεδο που θέλετε να δημιουργήσετε."
            );
            setIsErrorModalOpen(true);
          } else {
            setIsLoading(true);
            createArea(
              name,
              description,
              demRes,
              orthoRes,
              privacy,
              selectedFile
            )
              .then((response) => {
                createAreaSucc(response, navigate);
                setIsLoading(false);
              })
              .catch((error) => {
                createAreaErr(error, setErrorMessage, setIsErrorModalOpen);
                setIsLoading(false);
              })
              .finally(() => {
                setIsLoading(false); // Set loading to false when the request completes
              });
          }
        })
        .catch((error) => {
          console.error("Error checking area name:", error);
        });
    }
  };

  const checkAreaNameExists = (areaName) => {
    return retrieveAreas()
      .then((response) => {
        const existingAreas = response.data;
        const existingArea = existingAreas.find(
          (area) => area.name === areaName
        );

        return existingArea !== undefined;
      })
      .catch((error) => {
        console.log("Error retreiving areas: ", error);
        return false;
      });
  };

  if (isLoading) {
    // Display loading spinner while fetching data
    return (
      <AreaPageContainer>
        <LoadingSpinner />
      </AreaPageContainer>
    );
  }

  return (
    <Layout>
      <ErrorModal
        className="modal"
        isOpen={isErrorModalOpen}
        onRequestClose={() => setIsErrorModalOpen(false)}
        errorMessage={errorMessage}
      />
      <CreateAreaBody>
        <CreateAreaContainer>
          <CreateAreaTitle>Δημιουργία Οικοπέδου</CreateAreaTitle>
          <br />
          <CreateAreaForm onSubmit={handleCreateArea}>
            <CreateAreaInput
              id="areaName"
              name="areaName"
              type="text"
              placeholder="Όνομα"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />

            {/* <Label>Περιγραφή</Label> */}
            <CreateAreaInput
              id="areaDescription"
              name="areaDescription"
              type="text"
              placeholder="Περιγραφή"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />

            <CreateAreaInput
              id="demResolution"
              name="demResolution"
              type="number"
              placeholder="Εδαφοψηφίδα Μοντέλου (cm)"
              value={demRes !== null && demRes !== 0 ? demRes : ""}
              onChange={(e) =>
                setDemRes(
                  e.target.value !== "" ? parseFloat(e.target.value) : null
                )
              }
              min="0.1"
              step="0.1"
              required
            />

            <CreateAreaInput
              id="orthoResolution"
              name="orthoResolution"
              type="number"
              placeholder="Εδαφοψηφίδα Ορθοεικόνας (cm)"
              value={orthoRes !== null && orthoRes !== 0 ? orthoRes : ""}
              onChange={(e) =>
                setOrthoRes(
                  e.target.value !== "" ? parseFloat(e.target.value) : null
                )
              }
              min="0.1"
              step="0.1"
              required
            />

            <CreateAreaInput
              id="zipFile"
              name="zipFile"
              type="file"
              onChange={handleFileChange}
              accept=".zip"
              required
            />

            <CreateAreaRadioContainer>
              <CreateAreaRadioInput
                type="radio"
                id="public"
                name="privacy"
                value="public"
                checked={privacy === "public"}
                onChange={handlePrivacyChange}
              />
              <CreateAreaRadioLabel>Δημόσιο</CreateAreaRadioLabel>

              <CreateAreaRadioInput
                type="radio"
                id="private"
                name="privacy"
                value="private"
                checked={privacy === "private"}
                onChange={handlePrivacyChange}
              />
              <CreateAreaRadioLabel>Ιδιωτικό</CreateAreaRadioLabel>
            </CreateAreaRadioContainer>

            <CreateAreaButton disabled={isLoading}>Δημιουργία</CreateAreaButton>
          </CreateAreaForm>
        </CreateAreaContainer>
      </CreateAreaBody>
    </Layout>
  );
}
