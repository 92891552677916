import { Navigate, Outlet } from "react-router-dom";

import UserAuthorization from "../authorization/UserAuthorization";

/**
 * Private route component that checks if the user is authenticated.
 * If authenticated, renders the child routes, otherwise redirects to "/welcome".
 * @param {Object} props - Component props.
 * @returns {JS.Element} - Rendered component.
 */
const PrivateRoute = ({ children, ...rest }) => {
  return UserAuthorization.isAuthenticated() ? (
    <Outlet />
  ) : (
    <Navigate to="/welcome" />
  );
};

export default PrivateRoute;
