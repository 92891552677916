import axios from "axios";

/**
 * Function to retrieve a list of panels for a specific MPPT (Maximum Power Point Tracker) in an inverter in a park.
 * @param {string} parkId - The ID of the park containing the inverter.
 * @param {string} inverterId - The ID of the inverter containing the MPPT.
 * @param {string} mpptId - The ID of the MPPT containing the panels.
 * @returns {Promise} - Axios promise object representing the GET request.
 */
export const retrievePanels = (parkId, inverterId, mpptId) => {
  return axios.get(
    `places/parks/${parkId}/inverters/${inverterId}/mppts/${mpptId}/panels`
  );
};
