import { useLocation } from "react-router-dom";

import {
  EmailBody,
  EmailContainer,
  EmailRowContainer,
  Avatar,
  EmailTitle,
  Paragraph,
} from "../../styles/page/emailComponents";

import confirmEmailImg from "../../assets/verify-email.png";

/**
 * ConfirmEmail component represents the confirm email page.
 * Retrieves the email from the location state and displays it along with instructions.
 * @returns {JS.Element} - Rendered component.
 */
export default function ConfirmEmail() {
  // Get the location object from react-router-dom
  const location = useLocation();
  // Extract the email from the location state, or assign an empty string as default
  const { email } = location.state || { email: "" };

  return (
    <EmailBody>
      <EmailContainer>
        <EmailRowContainer>
          {" "}
          <Avatar image={confirmEmailImg} />
        </EmailRowContainer>

        <EmailTitle>
          Παρακαλώ, επιβεβαιώστε την διεύθυνση του ηλεκτρονικού σας ταχυδρομείου
        </EmailTitle>
        <Paragraph>
          Για να ολοκληρώσετε τη διαδικασία εγγραφής, παρακαλώ ακολουθήστε τις
          οδηγίες στο email που μόλις σας στείλαμε, στη διεύθυνση{" "}
          <strong>{email}</strong>.
          <br />
          <br />
          Επιβεβαιώστε τη διεύθυνση του ηλεκτρονικού σας ταχυδρομείου μέσα στα
          επόμενα 30 λεπτά. Εάν δεν παραλάβατε το email, ίσως πρέπει να ελέγξετε
          τον φάκελο ανεπιθύμητης αλληλογραφίας σας.
        </Paragraph>
      </EmailContainer>
    </EmailBody>
  );
}
