import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import axios from "axios";

import AuthForm from "./pages/public_routes/Welcome";
import Verification from "./pages/public_routes/EmailVerification";
import ConfirmEmail from "./pages/public_routes/ConfirmEmail";
import PrivateRoute from "./utils/PrivateRoute";
import Home from "./pages/private_routes/Home";
import AreaOversight from "./pages/private_routes/area/Area";
import ParkOversight from "./pages/private_routes/park/Park";
import CreatePark from "./pages/private_routes/park/CreatePark";
import ParkDetails from "./pages/private_routes/park/ParkDetails";
import PageNotFound from "./pages/public_routes/PageNotFound";
import CreateArea from "./pages/private_routes/area/CreateArea";
import AreaDetails from "./pages/private_routes/area/AreaDetails";
import Thermography from "./pages/private_routes/park/park_thermography/Thermography";

import "./App.css";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route element={<PrivateRoute />}>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/area-oversight" element={<AreaOversight />} />
            <Route exact path="/create-area" element={<CreateArea />} />
            <Route exact path="/areas/:id" element={<AreaDetails />} />

            <Route exact path="/park-oversight" element={<ParkOversight />} />
            <Route exact path="/create-park" element={<CreatePark />} />
            <Route exact path="/parks/:id" element={<ParkDetails />} />
            <Route
              exact
              path="/parks/:parkId/thermographies/:thermographyId/supervision"
              element={<Thermography />}
            />
          </Route>

          <Route path="/welcome" element={<AuthForm />} />
          <Route path="/confirm-email" element={<ConfirmEmail />} />
          <Route path="/email-verification" element={<Verification />} />
          {/* <Route element={<ForgotPassword />} exact path="/forgot-password" /> */}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
