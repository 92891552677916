import styled from "styled-components";

import trashbin from "../assets/trash-bin.png";
import editIcon from "../assets/pencil.png";

export const BaseContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const PageContainer = styled(BaseContainer)`
  flex-direction: column;
  justify-content: center;
`;

export const BaseTitle = styled.h1`
  font-family: inherit;
  color: #274a1c;
  font-weight: bold;
  white-space: normal;
`;

export const ModeTitle = styled(BaseTitle)`
  font-size: 28px;
  margin: 40px 0;
`;

export const PlaceTitle = styled(BaseTitle)``;

export const BaseH3 = styled.h3`
  font-family: inherit;
  color: #333;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  text-align: center;
`;

export const BaseTableContainer = styled.div`
  overflow: hidden;
`;

export const PlaceTableContainer = styled(BaseTableContainer)`
  width: 80%;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
`;

export const BaseTable = styled.table`
  width: 100%;
`;

export const PlaceTable = styled(BaseTable)`
  table-layout: fixed;
`;

export const BaseTableBody = styled.tbody``;

export const BaseTableThead = styled.thead``;

export const BaseTableRow = styled.tr``;

export const PlaceTableRow = styled(BaseTableRow)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

export const PlaceButtonRow = styled(BaseTableRow)`
  cursor: pointer;
  &:hover {
    background-color: #f1f1f1;
  }
`;

export const BaseTableHeader = styled.th`
  color: #fff;
  text-align: center;
  font-weight: bold;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

export const PlaceTableHeader = styled(BaseTableHeader)`
  background: #b8d669;
  background: -webkit-linear-gradient(to right, #cbca5e, #b8d669);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  font-size: 18px;
  padding: 15px;
`;

export const BaseTableCell = styled.td`
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`;

export const PlaceTableCell = styled(BaseTableCell)`
  position: relative;
  padding: 12px;
`;

export const PlaceDateCell = styled(PlaceTableCell)`
  white-space: nowrap;
`;

export const CreatePlaceBody = styled(PageContainer)`
  height: 100vh;
  margin: -20px 0 50px;
`;

export const CreatePlaceContainer = styled(PageContainer)`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  width: 800px;
  max-width: 100%;
  min-height: 500px;
`;

export const CreatePlaceTitle = styled(BaseTitle)`
  font-size: 25px;
  margin: 60px 0;
  margin-bottom: 0px;
`;

export const BaseForm = styled.form`
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
`;

export const CreatePlaceForm = styled(BaseForm)`
  margin-top: 0px;
`;

export const BaseInput = styled.input`
  font-family: inherit;
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
`;

export const CreatePlaceInput = styled(BaseInput)`
  width: 150%;
`;

export const BaseRadioContainer = styled.div`
  display: flex;
  flex-direction: row; /* Stack elements vertically */
`;

export const PlacesInfoRadioContainer = styled(BaseRadioContainer)`
  align-items: center;
  margin-top: 15px;
`;

export const EditPlacesInfoRadioContainer = styled(BaseRadioContainer)`
  align-items: flex-start; /* Align elements to the left */
  width: 100%;
  margin-top: 20px;
  margin-left: 90px;
  justify-content: left;
`;

export const BaseCheckboxInput = styled.input`
  cursor: pointer;
`;

export const BaseCheckboxLabel = styled.label`
  display: flex;
  font-family: inherit;
  align-items: center;
`;

export const BaseRadioInput = styled.input`
  cursor: pointer;
`;

export const PlacesInfoRadioInput = styled(BaseRadioInput)`
  margin-right: 5px;
`;

export const EditPlacesInfoRadioInput = styled(BaseRadioInput)`
  width: 18px;
  height: 18px;
  border-radius: 50%;
`;

export const BaseRadioLabel = styled.label`
  font-family: inherit;
`;

export const PlacesInfoRadioLabel = styled(BaseRadioLabel)`
  font-size: 12px;
  margin-left: 0px;
  margin-right: 15px;
`;

export const EditPlacesInfoRadioLabel = styled(BaseRadioLabel)`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-right: 25px;
`;

export const BaseButton = styled.button`
  cursor: pointer;
  font-family: inherit;
`;

export const CreatePlaceButton = styled(BaseButton)`
  margin: 45px 0;
  border-radius: 20px;
  border: 1px solid #b8d669;
  background-color: #b8d669;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  &:hover {
    transform: scale(1.05);
  }
  &:active {
    transform: scale(0.95);
  }
  &:focus {
    outline: none;
  }
`;

export const DownloadFilesButton = styled(BaseButton)`
  align-items: center;
  background: none;
  border: none;
  padding: 0;
  margin-left: 5px;
  opacity: 1;
  transition: opacity 0.9s;

  &:hover {
    transform: scale(1.1);
  }
  &:active {
    transform: scale(0.9);
  }
  &:focus {
    outline: none;
  }
`;

export const DownloadFilesImageButton = styled.img`
  width: 20px;
  height: 20px;
`;

export const PlaceButtonsContainer = styled(BaseContainer)`
  justify-content: flex-end; /* Position buttons to the right */
  flex-grow: 1; /* Allows buttons to take up available space */
`;

export const PlaceBaseButtonContainer = styled.div`
  background-size: cover;
  transition: opacity 0.3s;
  &:hover {
    transform: scale(1.2);
  }
`;

export const PlaceEditButtonContainer = styled(PlaceBaseButtonContainer)`
  background-image: url(${editIcon});
  width: 28px;
  height: 28px;
`;

export const PlaceDeleteButtonContainer = styled(PlaceBaseButtonContainer)`
  background-image: url(${trashbin});
  width: 30px;
  height: 30px;
  margin-left: 10px;
`;

export const BaseConfirmDeleteButton = styled(BaseButton)`
  width: 100px;
  border: none;
  border-radius: 10px;
  color: white;
  padding: 8px;
  margin-top: 8px;
`;

export const PlaceDeleteConfirmButton = styled(BaseConfirmDeleteButton)`
  margin-right: 8px;
  margin-left: 8px;
  background-color: #306b3e;

  &:hover {
    background-color: #4aaa61;
  }
`;

export const PlaceDeleteCancelButton = styled(BaseConfirmDeleteButton)`
  background-color: #a84d28;

  &:hover {
    background-color: #e53935;
  }
`;

export const PlaceInfoBackground = styled.div`
  padding: 20px; /* 20px top padding */
  display: flex;
  justify-content: center; /* Center the content horizontally */
`;

export const PlaceInfoWrapper = styled.div`
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: flex-start; /* Align elements to the left */
  width: 100%;
`;

export const BaseParagraph = styled.p`
  font-family: inherit;
  letter-spacing: 0.5px;
  margin: 0;
`;

export const PlaceInfoContainer = styled.div`
  width: 80%;
  display: flex;
  //align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8); /* Very light white */
  padding: 20px; /* Space inside the box */
  margin-bottom: 20px; /* Space between the box and the table */
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
`;

export const PlaceInfoID = styled(BaseParagraph)`
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
  margin-bottom: 20px;
`;

export const PlaceInfoDescription = styled(BaseParagraph)`
  font-size: 18px;
  font-weight: 100;
  line-height: 20px;
  text-align: left;
  height: 65px;
  overflow: auto; /* Add scrollbars when content overflows */
  white-space: pre-wrap; /* Preserve line breaks */
`;
