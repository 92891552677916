import { handleApiError } from "../apiErrorHandling";

/**
 * Handles a successful retrieval of panels for a specific MPPT (Maximum Power Point Tracker) in an inverter in a park from the API.
 * @param {Object} response - The Axios response object.
 * @param {string} parkId - The ID of the park containing the inverter.
 * @param {string} inverterId - The ID of the inverter containing the MPPT.
 * @param {string} mpptId - The ID of the MPPT containing the panels.
 * @param {Function} setPanels - Function to set the retrieved panels in the component state.
 */
export const retrievePanelsSucc = (
  response,
  parkId,
  inverterId,
  mpptId,
  setPanels
) => {
  if (response.status === 200) {
    const panelData = response.data.map((item) => ({
      id: item.id,
      manufacturer: item.manufacturer,
      model: item.model,
      power: item.power,
      inverterId: inverterId, // Add inverterId
      mpptId: mpptId, // Add mpptId
      parkId: parkId,
    }));

    setPanels(panelData);
  }
};

/**
 * Handles an error during the retrieval of panels for a specific MPPT (Maximum Power Point Tracker) in an inverter in a park from the API.
 * @param {Object} error - The Axios error object.
 * @param {Function} setErrorMessage - Function to set an error message in the component state.
 * @param {Function} setIsErrorModalOpen - Function to control the error modal's visibility.
 */
export const retrievePanelsErr = (
  error,
  setErrorMessage,
  setIsErrorModalOpen
) => {
  handleApiError(error, setErrorMessage, setIsErrorModalOpen);
};
