import axios from "axios";

/**
 * Function to retrieve the current user information.
 * @returns {Promise} - Axios promise object representing the GET request.
 */
export const currUser = () => {
  // Make a GET request to the current user information endpoint
  return axios.get("users/me");
};

/**
 * Function to handle user registration.
 * @param {string} user - The username.
 * @param {string} email - The email.
 * @param {string} pwd - The password.
 * @returns {Promise} - Axios promise object representing the POST request.
 */
export const register = (user, email, pwd) => {
  // Create data object to send in the request
  const data = {
    username: user,
    email: email,
    password: pwd,
  };

  // Make a POST request to the registration endpoint
  return axios.post("users/self-register", data);
};

/**
 * Perform a user search based on a keyword.
 *
 * @param {string} keyword - The search term or keyword to search for.
 * @returns {Promise} A Promise that resolves to the search results.
 * @throws {Error} If the search operation fails, an error is thrown.
 */
export const search = (keyword) => {
  return axios.get(`users/search?term=${keyword}`);
};

/**
 * Send an invasion request to invite a user to a specific place or location.
 *
 * @param {int} placeId - The unique identifier of the place.
 * @param {str} userId - The unique identifier of the user to be invited.
 * @returns {Promise} A Promise that resolves when the invasion request is sent successfully.
 * @throws {Error} If the invasion request fails, an error is thrown.
 */
export const invite = (placeId, userId) => {
  const data = {
    place_id: placeId,
    user_id: userId,
  };
  return axios.post(`/places/members/invite`, data);
};

/**
 * Retrieve the member of a place.
 *
 * @param {int} placeId - The unique identifier of the place.
 * @returns {Promise} A Promise that resolves when the invasion request is sent successfully.
 * @throws {Error} If the invasion request fails, an error is thrown.
 */
export const retrieveMembersOfPlace = (placeId) => {
  return axios.get(`/places/members?place_id=${placeId}`);
};
