import axios from "axios";

/**
 * Function to handle the retrieval of the parks.
 * @returns {Promise} - Axios promise object representing the GET request.
 */
export const retrieveParks = () => {
  // Make a GET request to retrieve parks endpoint
  return axios.get("places/parks", {});
};

/**
 * Function to handle the creation of each park.
 * @param {string} name - The park name.
 * @param {string} description - The park description.
 * @param {string} priv - The privacy setting.
 * @param {File} spreadsheetData - The selected Excel file (optional).
 * @returns {Promise} - Axios promise object representing the POST request.
 */
export const createPark = (name, description, priv, spreadsheetData) => {
  // Prepare data for park creation
  const formData = new FormData();
  formData.append("name", name);
  formData.append("description", description);
  formData.append("private", priv === "private");

  // Append the spreadsheetData if available
  if (spreadsheetData) {
    formData.append("spreadsheet_data", spreadsheetData);
  }

  // Make a POST request to create a park endpoint
  return axios.post("places/parks", formData, {
    headers: {
      "Content-Type": "multipart/form-data", // Set the content type to form-data
    },
  });
};

/**
 * Function to handle the retrieval of a specific park.
 * @param {string} parkId - The ID of the park to retrieve.
 * @returns {Promise} - Axios promise object representing the GET request.
 */
export const retrievePark = (parkId) => {
  // Make a GET request to retrieve the specific park
  return axios.get(`places/parks/${parkId}`);
};

/**
 * Function to handle the deletion of a specific park.
 * @param {string} parkId - The ID of the park to delete.
 * @returns {Promise} - Axios promise object representing the DELETE request.
 */
export const deletePark = (parkId) => {
  // Make a DELETE request to delete the specific park
  return axios.delete(`places/parks/${parkId}`);
};

/**
 * Function to handle the update of a specific park.
 * @param {string} parkId - The ID of the park to update.
 * @returns {Promise} - Axios promise object representing the PATCH request.
 */
export const updatePark = (parkId, updatedParkData) => {
  // Make a PATCH request to update the specific park
  return axios.patch(`places/parks/${parkId}`, updatedParkData);
};

// /**
//  * Function to handle the creation of each park.
//  * @param {string} name - The park name.
//  * @param {string} description - The park description.
//  * @param {string} priv - The privacy setting.
//  * @returns {Promise} - Axios promise object representing the POST request.
//  */
// export const createPark = (name, description, priv) => {
//   // Prepare data for park creation
//   const data = {
//     name: name,
//     description: description,
//     private: priv === "private", // Will be true when priv is 'private', false otherwise
//   };

//   // Make a POST request to create a park endpoint
//   return axios.post("places/parks", data);
// };
