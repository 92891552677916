import axios from "axios";

/**
 * Function to retrieve a list of areas.
 * @returns {Promise} - Axios promise object representing the GET request.
 */
export const retrieveAreas = () => {
  return axios.get("/places/areas", {});
};

/**
 * Function to create a new area.
 * @param {string} name - The name of the area.
 * @param {string} description - The description of the area.
 * @param {string} demRes - DEM (Digital Elevation Model) resolution.
 * @param {string} orthoRes - Orthophoto resolution.
 * @param {string} priv - Privacy status (either 'private' or 'public').
 * @param {Blob} zipData - ZIP file containing area data.
 * @returns {Promise} - Axios promise object representing the POST request.
 */
export const createArea = (
  name,
  description,
  demRes,
  orthoRes,
  priv,
  zipData
) => {
  const formData = new FormData();
  formData.append("name", name);
  formData.append("description", description);
  formData.append("dem_resolution", demRes);
  formData.append("orthophoto_resolution", orthoRes);
  formData.append("private", priv === "private");
  formData.append("file", zipData);

  return axios.post("/places/areas", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

/**
 * Function to retrieve information about a specific area.
 * @param {string} areaId - The ID of the area to retrieve.
 * @returns {Promise} - Axios promise object representing the GET request.
 */
export const retrieveArea = (areaId) => {
  return axios.get(`places/areas/${areaId}`);
};

/**
 * Function to delete a specific area.
 * @param {string} areaId - The ID of the area to delete.
 * @returns {Promise} - Axios promise object representing the DELETE request.
 */
export const deleteArea = (areaId) => {
  return axios.delete(`places/areas/${areaId}`);
};

/**
 * Function to update information about a specific area.
 * @param {string} areaId - The ID of the area to update.
 * @param {object} updatedAreaData - The request body of the area
 * @returns {Promise} - Axios promise object representing the PATCH request.
 */
export const updateArea = (areaId, updatedAreaData) => {
  return axios.patch(`places/areas/${areaId}`, updatedAreaData);
};
