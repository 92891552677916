import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import { emailVerification } from "../../api/calls/emailCall";
import { emailVerificationSucc, emailVerificationErr } from "../../api/responses/emailResponse";

import {
  EmailBody,
  EmailContainer,
  EmailRowContainer,
  Avatar,
  EmailTitle,
} from "../../styles/page/emailComponents";

import emailVerifiedImg from "../../assets/email-verified.png";
import emailNotVerifiedImg from "../../assets/email-not-verified.png";

/**
 * EmailVerification component represents the email verification page.
 * Performs email verification based on the token from the URL query string.
 * Displays different messages based on the verification status.
 * @returns {JS.Element} - Rendered component.
 */
export default function EmailVerification() {
  // Get the location object from react-router-dom
  const location = useLocation();

  // Parse the query string to get the token
  const { token } = queryString.parse(location.search); // parse the query string to get the token

  // State variable for the message to display
  const [message, setMessage] = useState("");

  useEffect(() => {
    // Call the email verification API function
    emailVerification(token)
      .then((response) => {
        emailVerificationSucc(response, setMessage);
      })
      .catch((error) => {
        emailVerificationErr(error, setMessage);
      });
  }, [token]);

  return (
    <EmailBody>
      {/* Conditionally render components based on the message */}
      {message === "verification done" ? (
        <>
          <EmailContainer>
            <EmailRowContainer>
              {" "}
              <Avatar image={emailVerifiedImg} />
            </EmailRowContainer>
            <EmailTitle>
              Η διεύθυνση του ηλεκτρονικού σας ταχυδρομείου επιβεβαιώθηκε
              επιτυχώς!
            </EmailTitle>
          </EmailContainer>
        </>
      ) : message === "email already verified" ? (
        <>
          <EmailContainer>
            <EmailTitle>
              Η διεύθυνση του ηλεκτρονικού ταχυδρομείου σας έχει ήδη
              πραγματοποιηθεί!
            </EmailTitle>
          </EmailContainer>
        </>
      ) : (
        <>
          <EmailContainer>
            <EmailRowContainer>
              {" "}
              <Avatar image={emailNotVerifiedImg} />
            </EmailRowContainer>
            <EmailTitle>
              Η διεύθυνση του ηλεκτρονικού σας ταχυδρομείου δεν
              πραγματοποιήθηκε!
            </EmailTitle>
          </EmailContainer>
        </>
      )}
    </EmailBody>
  );
}