import styled from "styled-components";

import { BaseButton, BaseContainer, BaseParagraph } from "../sharedStyles";

export const ConfirmationModalContainer = styled(BaseContainer)`
  margin-top: 15px;
  justify-content: center;
  zindex: 1020;
  backgroundcolor: "rgba(0, 0, 0, 0.5)";
`;

export const ConfirmationModalContent = styled.div`
  position: relative;
  max-width: 100%;
  overflow-y: auto;
  border-radius: 8px;
  background-color: white;
  padding: 5px;
`;

export const ConfirmationMessage = styled(BaseParagraph)`
  margin-top: 3px;
  color: #274a1c;
`;

export const ConfirmationButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 7px;
`;

const BaseConfirmationButton = styled(BaseButton)`
  margin-top: 8px;
  color: white;
  padding: 8px;
  width: 40%;
  border: none;
`;

export const ConfirmationCancelButton = styled(BaseConfirmationButton)`
  background-color: #a84d28;
  &:hover {
    background-color: #e53935;
  }
`;

export const ConfirmationConfirmButton = styled(BaseConfirmationButton)`
  background-color: #306b3e;
  &:hover {
    background-color: #4aaa61;
  }
`;
