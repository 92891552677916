import { Link, useNavigate } from "react-router-dom";

import UserAuthorization from "../authorization/UserAuthorization";

import {
  NavbarContainer,
  HomeButtonContainer,
  HomeText,
  LogoutButtonContainer,
  LogoutText,
} from "../styles/components/navbarComponents";


/**
 * Navbar component represents the navigation bar.
 * Renders the logo and logout button.
 * @returns {JS.Element} - Rendered component.
 */
const Navbar = () => {
  // Hook for navigation
  const navigate = useNavigate();

  // Handle logout
  const handleLogout = () => {
    // Call the logout method from UserAuthorization
    UserAuthorization.logout();
    // Navigate to the welcome page
    navigate("/welcome");
  };

  return (
    <NavbarContainer>
      {/* Logo */}
      <Link to="/">
        <HomeButtonContainer>
          <HomeText>Αρχική</HomeText>
        </HomeButtonContainer>
      </Link>
      {/* Logout button */}
      <LogoutButtonContainer onClick={handleLogout}>
        <LogoutText>Έξοδος</LogoutText>
      </LogoutButtonContainer>
    </NavbarContainer>
  );
};

export default Navbar;
