import styled from "styled-components";

export const TabsContainer = styled.div`
  width: 100%;
`;

export const TabButtons = styled.div`
  display: flex;
`;

export const TabButton = styled.button`
  justify-content: center;
  gap: 20px;
  background-color: transparent;
  border: none;
  color: ${(props) => (props.active ? "#298532" : "#374238")};
  font-family: inherit;
  font-size: 26px;
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  cursor: pointer;
  margin: 0 50px 0 50px;
  padding: 10px 20px;
  transition: color 0.3s ease-in-out;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 50%;

  border-bottom: ${(props) =>
    props.active ? "2px solid #298532" : "1px solid rgba(239, 237, 239, 0.5)"};

  &:hover,
  &:focus {
    border-bottom: 2px solid #298532;
  }
`;

export const TabContent = styled.div`
  color: black;
  padding: 20px;
`;