import axios from "axios";

import { currUser } from "../api/calls/userCall";
import { currUserSucc, currUserErr } from "../api/responses/userResponse";

/**
 * Class that handles the authentication system of the application
 */

class UserAuthorization {
  constructor() {
    this.token = "";
  }

  /**
   * Implements the storing of user information. Those information are stored in the browser cache
   * @param {object } response
   * @param {bool} remember
   * @param {function} cb what to do after that
   */
  login(response, cb) {
    this.token = response.data.access_token;

    localStorage.setItem("token", this.token);

    cb();
  }

  /**
   * Implements the logout functionality
   */
  logout() {
    localStorage.clear();
  }

  /**
   * Checks if the user is authenticated.
   * @returns {boolean} - True if the user is authenticated, false otherwise.
   */
  isAuthenticated() {
    let token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      return true;
    }
    return false;
  }

  /**
   * Checks if the user is active.
   * @returns {boolean} - True if the user is active, false otherwise.
   */
  isActive() {
    currUser
      .then((response) => {
        currUserSucc(response);
      })
      .catch((error) => {
        currUserErr(error);
      });
  }
}

export default new UserAuthorization();