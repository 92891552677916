/**
 * RandomColor class generates random colors using a seed-based approach
 * and provides methods to manipulate and retrieve color information.
 */
export class RandomColor {
  /**
   * Constructor for RandomColor class.
   * @param {number} startSeed - The starting seed for random color generation.
   * @param {number} seedStep - The step size for updating the seed during color generation.
   */
  constructor(startSeed = 6, seedStep = 90) {
    this.seed = startSeed;
    this.seedStep = seedStep;
    this.red = 0;
    this.green = 0;
    this.blue = 0;
  }

  /**
   * Generates a random hue value based on the current seed.
   * @returns {number} - The generated hue value.
   */
  generateHue() {
    var x = Math.sin(this.seed * (Math.PI / 180));
    this.seed += this.seedStep;
    return x - Math.floor(x);
  }

  /**
   * Samples a random color by generating a hue and converting it to RGB.
   * @returns {RandomColor} - The RandomColor instance for method chaining.
   */
  SampleColor() {
    let h = this.generateHue();
    let s = 1;
    let v = 1;
    this.setRGBFromHSV(h, s, v);
    return this;
  }

  /**
   * Sets the red component of the color.
   * @param {number} red - The red component value (0 to 255).
   * @returns {RandomColor} - The RandomColor instance for method chaining.
   */
  SetRed(red) {
    this.red = red;
    return this;
  }

  /**
   * Sets the green component of the color.
   * @param {number} green - The green component value (0 to 255).
   * @returns {RandomColor} - The RandomColor instance for method chaining.
   */
  SetGreen(green) {
    this.green = green;
    return this;
  }

  /**
   * Sets the blue component of the color.
   * @param {number} blue - The blue component value (0 to 255).
   * @returns {RandomColor} - The RandomColor instance for method chaining.
   */
  SetBlue(blue) {
    this.blue = blue;
    return this;
  }

  /**
   * Sets the alpha (transparency) component of the color.
   * @param {number} alpha - The alpha component value (0 to 1).
   * @returns {RandomColor} - The RandomColor instance for method chaining.
   */
  SetAlpha(alpha) {
    this.alpha = alpha;
    return this;
  }

  /**
   * Converts the color components to an RGBA string.
   * @returns {string} - The RGBA representation of the color.
   */
  String() {
    return `rgba(${this.red}, ${this.green}, ${this.blue}, ${this.alpha})`;
  }

  /**
   * Converts HSV (Hue, Saturation, Value) values to RGB (Red, Green, Blue).
   * @param {number} h - Hue value (0 to 1).
   * @param {number} s - Saturation value (0 to 1).
   * @param {number} v - Value (brightness) value (0 to 1).
   */
  setRGBFromHSV(h, s, v) {
    let i, f, p, q, t;

    // Adjust arguments if passed as an object
    if (arguments.length === 1) {
      s = h.s;
      v = h.v;
      h = h.h;
    }

    i = Math.floor(h * 6);
    f = h * 6 - i;
    p = v * (1 - s);
    q = v * (1 - f * s);
    t = v * (1 - (1 - f) * s);

    // Switch based on the sextant of the color wheel
    switch (i % 6) {
      default:
      case 0:
        this.red = v * 255;
        this.green = t * 255;
        this.blue = p * 255;
        break;
      case 1:
        this.red = q * 255;
        this.green = v * 255;
        this.blue = p * 255;
        break;
      case 2:
        this.red = p * 255;
        this.green = v * 255;
        this.blue = t * 255;
        break;
      case 3:
        this.red = p * 255;
        this.green = q * 255;
        this.blue = v * 255;
        break;
      case 4:
        this.red = t * 255;
        this.green = p * 255;
        this.blue = v * 255;
        break;
      case 5:
        this.red = v * 255;
        this.green = p * 255;
        this.blue = q * 255;
        break;
    }
  }
}
