import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { register } from "../api/calls/userCall";
import { registerSucc, registerErr } from "../api/responses/userResponse";

import ErrorModal from "./modal/ErrorModal";

import {
  Form,
  FormTitle,
  FormInput,
  Label,
  Button,
} from "../styles/page/loginRegisterComponents";

/**
 * RegisterForm component represents the registration form.
 * @returns {JS.Element} - Rendered component.
 */
function RegisterForm() {
  // Hook for navigation
  const navigate = useNavigate();

  // State variables for registration form
  const [user, setUser] = useState("");
  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [confPwd, setConfPwd] = useState("");

  // State variables for error handling
  const [registerErrMsg, setRegisterErrMsg] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  // State variable for password confirmation error handling
  const [confPwdErrMsg, setConfPwdErrMsg] = useState("");

  useEffect(() => {
    // Close the error modal automatically after 3 seconds
    if (isErrorModalOpen) {
      const timeout = setTimeout(() => {
        setIsErrorModalOpen(false);
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, [isErrorModalOpen]);

  /**
   * Function that handles the registration form submission.
   * Calls the register API function and performs necessary actions based on the response.
   * @param {Object} e - Event object.
   */
  const handleSignUp = (e) => {
    e.preventDefault();

    // Check if password and confirmation password are the same
    if (pwd !== confPwd) {
      // If not, set the password confirmation error message and do not proceed with the API call
      setConfPwdErrMsg("Passwords do not match!");
      return;
    }

    register(user, email, pwd)
      .then((response) => {
        registerSucc(response, navigate, email);
      })
      .catch((error) => {
        registerErr(error, setRegisterErrMsg, setIsErrorModalOpen);
      });
  };

  return (
    <Form onSubmit={handleSignUp}>
      {/* Error modal */}
      <ErrorModal
        className="modal"
        isOpen={isErrorModalOpen}
        onRequestClose={() => setIsErrorModalOpen(false)}
        errorMessage={registerErrMsg}
      />
      {/* Registration form */}
      <FormTitle style={{ fontSize: 25, marginBottom: 15, color: "green" }}>
        Δημιουργία Λογαριασμού
      </FormTitle>
      <FormInput
        id="username"
        name="username"
        type="text"
        placeholder="Όνομα χρήστη"
        value={user}
        onChange={(e) => setUser(e.target.value)}
        required
      />
      <FormInput
        id="email"
        name="email"
        type="email"
        placeholder="Διεύθυνση ηλεκτρονικού ταχυδρομείου"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <FormInput
        id="password"
        name="password"
        type="password"
        placeholder="Κωδικός πρόσβασης"
        value={pwd}
        onChange={(e) => setPwd(e.target.value)}
        required
      />
      <FormInput
        id="confirmPassword"
        name="confirmPassword"
        type="password"
        placeholder="Επιβεβαίωση κωδικού πρόσβασης"
        value={confPwd}
        onChange={(e) => setConfPwd(e.target.value)}
        required
      />
      {confPwdErrMsg && <Label>{confPwdErrMsg}</Label>}
      <Button>Εγγραφη</Button>
    </Form>
  );
}

export default RegisterForm;
