import React from "react";
import ReactModal from "react-modal";

import {
  ConfirmationModalContainer,
  ConfirmationModalContent,
  ConfirmationMessage,
  ConfirmationButtonsContainer,
  ConfirmationCancelButton,
  ConfirmationConfirmButton,
} from "../../styles/modals/confirmationModalComponents";

function ConfirmationModal({ isOpen, onCancel, onConfirm, message }) {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onCancel}
      ariaHideApp={false}
      style={{
        overlay: {
          zIndex: 1020,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        content: {
          position: "absolute",
          textAlign: "center",
          maxWidth: "100%",
          maxHeight: "18%",
          overflowY: "auto",
          marginTop: 45,
          boxSizing: "border-box",
          borderRadius: "8px",
          backgroundColor: "white",
        },
      }}
    >
      <ConfirmationModalContainer>
        <ConfirmationModalContent>
          <ConfirmationMessage>{message}</ConfirmationMessage>
          <ConfirmationButtonsContainer>
            <ConfirmationCancelButton onClick={onCancel}>
              Ακύρωση
            </ConfirmationCancelButton>
            <ConfirmationConfirmButton onClick={onConfirm}>
              Επιβεβαίωση
            </ConfirmationConfirmButton>
          </ConfirmationButtonsContainer>
        </ConfirmationModalContent>
      </ConfirmationModalContainer>
    </ReactModal>
  );
}

export default ConfirmationModal;
