import styled from "styled-components";
import logo from "../../assets/logo_pvid.png";
import logout from "../../assets/logout2.png";

export const NavbarContainer = styled.div`
  background: linear-gradient(to bottom, #d2c55a, #b8d669);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #f2f2f2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  font-family: inherit;
`;

export const LogoutButtonContainer = styled.div`
  position: relative;
  width: 45px;
  height: 45px;
  cursor: pointer;
  background-image: url(${logout});
  background-size: cover;
  transition: opacity 0.9s;

  &:hover {
    transform: scale(1.2);
  }
`;

export const LogoutText = styled.span`
  position: absolute;
  top: 50%;
  left: -35px;
  transform: translate(-50%, -50%);
  font-size: 15px;
  font-weight: bold;
  color: white;
  opacity: 0; /* Start with 0 opacity and fade in on hover */
  transition: opacity 0.9s;

  ${LogoutButtonContainer}:hover & {
    opacity: 1;
  }
`;

export const HomeButtonContainer = styled.div`
  position: relative;
  width: 55px;
  height: 55px;
  cursor: pointer;
  background-image: url(${logo});
  background-size: cover;
  transition: opacity 0.9s;

  &:hover {
    transform: scale(1.07);
  }50
`;

export const HomeText = styled.span`
  position: absolute;
  top: 50%;
  right: -85px;
  transform: translate(-50%, -50%);
  font-size: 15px;
  font-weight: bold;
  color: white;
  opacity: 0; /* Start with 0 opacity and fade in on hover */
  transition: opacity 0.9s;

  ${HomeButtonContainer}:hover & {
    opacity: 1;
  }
`;
