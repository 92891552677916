import { handleApiError } from "../apiErrorHandling";

/**
 * Handles a successful retrieval of areas from the API.
 * @param {Object} response - The Axios response object.
 * @param {Function} setAreas - Function to set the areas in the component state.
 */
export const retrieveAreasSucc = (response, setAreas) => {
  if (response.status === 200) {
    setAreas(response.data);
  }
};

/**
 * Handles an error during the retrieval of areas from the API.
 * @param {Object} error - The Axios error object.
 * @param {Function} setErrorMessage - Function to set an error message in the component state.
 * @param {Function} setIsErrorModalOpen - Function to control the error modal's visibility.
 */
export const retrieveAreasErr = (
  error,
  setErrorMessage,
  setIsErrorModalOpen
) => {
  handleApiError(error, setErrorMessage, setIsErrorModalOpen);
};

/**
 * Handles a successful retrieval of a specific area from the API.
 * @param {Object} response - The Axios response object.
 * @param {Function} setArea - Function to set the specific area data in the component state.
 * @param {Function} setCheckpoints - Function to set the checkpoints area data in the component state.
 */
export const retrieveAreaSucc = (response, setArea, setCheckpoints, setPrivate) => {
  if (response.status === 200) {
    setArea(response.data);
    setCheckpoints(response.data.checkpoint);
    setPrivate(response.data.is_private)
  }
};

/**
 * Handles an error during the retrieval of a specific area from the API.
 * @param {Object} error - The Axios error object.
 * @param {Function} setErrorMessage - Function to set an error message in the component state.
 * @param {Function} setIsErrorModalOpen - Function to control the error modal's visibility.
 */
export const retrieveAreaErr = (
  error,
  setErrorMessage,
  setIsErrorModalOpen
) => {
  handleApiError(error, setErrorMessage, setIsErrorModalOpen);
};

/**
 * Handles a successful deletion of a specific area from the API.
 * @param {Object} response - The Axios response object.
 * @param {Function} navigate - Function to navigate to a different route/page.
 */
export const deleteAreaSucc = (response, navigate) => {
  navigate("/area-oversight");
};

/**
 * Handles an error during the deletion of a specific area from the API.
 * @param {Object} error - The Axios error object.
 * @param {Function} setErrorMessage - Function to set an error message in the component state.
 * @param {Function} setIsErrorModalOpen - Function to control the error modal's visibility.
 */
export const deleteAreaErr = (error, setErrorMessage, setIsErrorModalOpen) => {
  handleApiError(error, setErrorMessage, setIsErrorModalOpen);
};

/**
 * Handles a successful creation of a new area via the API.
 * @param {Object} response - The Axios response object.
 * @param {Function} navigate - Function to navigate to a different route/page.
 */
export const createAreaSucc = (response, navigate) => {
  if (response.status === 201) {
    navigate("/area-oversight");
  }
};

/**
 * Handles an error during the creation of a new area via the API.
 * @param {Object} error - The Axios error object.
 * @param {Function} setErrorMessage - Function to set an error message in the component state.
 * @param {Function} setIsErrorModalOpen - Function to control the error modal's visibility.
 */
export const createAreaErr = (error, setErrorMessage, setIsErrorModalOpen) => {
  handleApiError(error, setErrorMessage, setIsErrorModalOpen);
};

/**
 * Handles a successful update of area information via the API.
 * @param {Object} response - The Axios response object.
 */
export const updateAreaSucc = (response, updatedAreaData, setArea) => {
  if (response.status === 200) {
    setArea((prevArea) => ({
      ...prevArea,
      ...updatedAreaData,
    }));
  }
};

/**
 * Handles an error during the update of area information via the API.
 * @param {Object} error - The Axios error object.
 * @param {Function} setErrorMessage - Function to set an error message in the component state.
 * @param {Function} setIsErrorModalOpen - Function to control the error modal's visibility.
 */
export const updateAreaErr = (error, setErrorMessage, setIsErrorModalOpen) => {
  handleApiError(error, setErrorMessage, setIsErrorModalOpen);
};
