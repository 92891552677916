import React, { useState, useEffect } from "react";
import ErrorModal from "../../../../../components/modal/ErrorModal";
import LoadingSpinner from "../../../../../utils/LoadingSpinner";

import { retrieveTelemetryData } from "../../../../../api/calls/thermographyCall";

import {
  retrieveTelemetryDataErr,
  retrieveTelemetryDataSucc,
} from "../../../../../api/responses/thermographyResponse";

import "chartjs-adapter-moment";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import {
  telemetryInverterChartDatasets,
  telemetryMpptVoltageChartDatasets,
  telemetryMpptEnergyChartDatasets,
} from "../chart/chartDatasets";

import {
  optionsInverterEnergy,
  optionsMpptEnergy,
  optionsMpptVoltage,
} from "../chart/chartOptions";


import {
  ParkPageContainer,
  TelemetryDivContainer,
  NoTelemetryDataParagraph,
  TelemetryParkTotalContainer,
  TelemetryEnergyContainer,
  TelemetryPowerContainer,
  TelemetryParkTotalParagraph,
  TelemetryChartContainer,
  TelemetryPhraseWrapper,
  TelemetryChartContainerWrapper,
  TelemetryInverterChartContainer,
  TelemetryMpptChartContainer,
  TelemetryToggleButton,
  TelemetryBottomContainer,
  TelemetryMpptToggleContainer,
  TelemetryInverterTableContainer,
  TelemetryInverterPowerTable,
  TelemetryInverterPowerRow,
  TelemetryInverterPowerTableCell
} from "../../../../../styles/page/parkComponents";

ChartJS.register(
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Toggle = ({ toggle, handleToggleChange }) => {
  const phrase1 = "Ισχύς Στοιχειοσειράς";
  const phrase2 = "Τάση Στοιχειοσειράς";

  return (
    <TelemetryToggleButton toggle={toggle} onClick={handleToggleChange}>
      <TelemetryPhraseWrapper>
        {toggle ? phrase1 : phrase2}
      </TelemetryPhraseWrapper>
    </TelemetryToggleButton>
  );
};

export default Toggle;

export const TelemetryDetailsTabContent = ({ parkId, thermographyId }) => {
  const [telemetryData, setTelemetryData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const [toggle, setToggle] = useState(false);

  const [isExpanded, setIsExpanded] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (parkId && thermographyId) {
      setIsLoading(true);
      retrieveTelemetryData(parkId, thermographyId)
        .then((response) => {
          retrieveTelemetryDataSucc(response, setTelemetryData);
        })

        .catch((error) => {
          retrieveTelemetryDataErr(error, setErrorMessage, setIsErrorModalOpen);
        })
        .finally(() => {
          setIsLoading(false); // Set loading to false when the request completes
        });
    }
  }, [parkId, thermographyId]);

  const fetchInvertersMaxPower = (parkId, thermographyId) => {};

  const onToggleChange = () => {
    setToggle(!toggle);
  };

  const handleClick = () => {
    setIsExpanded((prevExpanded) => !prevExpanded); // Toggle the isExpanded state

    if (!isExpanded) {
      fetchInvertersMaxPower(parkId, thermographyId);
    }
  };

  const sortedInverters = telemetryData.inverters
    ? [...telemetryData.inverters].sort((a, b) => a.inverter_id - b.inverter_id)
    : [];

  if (isLoading) {
    // Display loading spinner while fetching data
    return (
      <ParkPageContainer>
        <LoadingSpinner />
      </ParkPageContainer>
    );
  }

  if (!telemetryData || Object.keys(telemetryData).length === 0) {
    return (
      <ParkPageContainer>
        <TelemetryDivContainer>
          <NoTelemetryDataParagraph>
            Δεν βρέθηκαν δεδομένα ενέργειας.
          </NoTelemetryDataParagraph>
        </TelemetryDivContainer>
      </ParkPageContainer>
    );
  }

  return (
    <ParkPageContainer>
      <ErrorModal
        className="modal"
        isOpen={isErrorModalOpen}
        onRequestClose={() => setIsErrorModalOpen(false)}
        errorMessage={errorMessage}
      />

      <TelemetryParkTotalContainer>
        <TelemetryEnergyContainer>
          <TelemetryParkTotalParagraph>
            Συνολική Ημερήσια Παραγωγή Πάρκου:{" "}
            {telemetryData.pv_park_total_power} kWh
          </TelemetryParkTotalParagraph>
        </TelemetryEnergyContainer>
        <TelemetryPowerContainer>
          <TelemetryParkTotalParagraph>
            Συνολική Ημερήσια Ισχύς Πάρκου: {telemetryData.pv_park_total_energy}{" "}
            kW
          </TelemetryParkTotalParagraph>
        </TelemetryPowerContainer>
      </TelemetryParkTotalContainer>

      <TelemetryChartContainer>
        <TelemetryChartContainerWrapper>
          <TelemetryInverterChartContainer>
            <Line
              options={optionsInverterEnergy}
              data={{
                datasets: telemetryInverterChartDatasets(telemetryData),
              }}
            />
          </TelemetryInverterChartContainer>

          <TelemetryMpptChartContainer>
            <Line
              options={toggle ? optionsMpptVoltage : optionsMpptEnergy}
              data={{
                datasets: toggle
                  ? telemetryMpptVoltageChartDatasets(telemetryData)
                  : telemetryMpptEnergyChartDatasets(telemetryData),
              }}
            />
          </TelemetryMpptChartContainer>
        </TelemetryChartContainerWrapper>
      </TelemetryChartContainer>

      <TelemetryBottomContainer>
        <TelemetryInverterTableContainer>
          <TelemetryInverterPowerTable>
            <TelemetryInverterPowerRow
              onClick={handleClick}
              isExpanded={isExpanded}
              style={{
                backgroundColor: "#bad568",
                borderRadius: "10px !important",
                cursor: "pointer",
              }}
            >
              <TelemetryInverterPowerTableCell style={{ width: "50%" }}>
                Κωδικός Μετατροπέα
              </TelemetryInverterPowerTableCell>
              <TelemetryInverterPowerTableCell style={{ width: "50%" }}>
                Μέγιστη Ημερήσια Ισχύς
              </TelemetryInverterPowerTableCell>
            </TelemetryInverterPowerRow>

            {isExpanded && (
              <>
                {sortedInverters.map((inverter) => (
                  <TelemetryInverterPowerRow key={inverter.inverter_id}>
                    <TelemetryInverterPowerTableCell style={{ width: "50%" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {inverter.inverter_id}
                      </div>
                    </TelemetryInverterPowerTableCell>
                    <TelemetryInverterPowerTableCell style={{ width: "50%" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {inverter.power} kW
                      </div>
                    </TelemetryInverterPowerTableCell>
                  </TelemetryInverterPowerRow>
                ))}
              </>
            )}
          </TelemetryInverterPowerTable>
        </TelemetryInverterTableContainer>

        <TelemetryMpptToggleContainer>
          {" "}
          <Toggle toggle={toggle} handleToggleChange={onToggleChange} />
        </TelemetryMpptToggleContainer>
      </TelemetryBottomContainer>
    </ParkPageContainer>
  );
};
