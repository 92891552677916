import styled, { keyframes } from "styled-components";

const spinnerAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const LoadingSpinnerContainer = styled.div`
  width: 100px;
  height: 100px;
  position: absolute;
  top: 47%;
  left: 47%;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Black */
  border-radius: 50%;
  animation: ${spinnerAnimation} 1.5s linear infinite;
`;

export const LoadingSpinnerOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent black background */
  z-index: 1; /* Ensure the overlay is above other content */
`;

