import styled from "styled-components";

import { BaseContainer, BaseParagraph } from "../sharedStyles";

export const UsersContainer = styled.div`
  width: 100%;
  max-width: 675px;
  margin: 20px 0 0 5px;
`;

export const UserCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f5f5;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: #e5e5e5;
  }
`;

export const UserInfo = styled(BaseContainer)``;

export const UserText = styled(BaseParagraph)`
  font-size: 16px;
  margin-left: 15px;
  color: "#333";
  display: flex;
  align-items: center;
`;

export const Username = styled(BaseParagraph)`
  font-size: 16px;
  margin: 0;
  color: "#666";
  margin-left: 10px;
  font-weight: bold;
  text-transform: capitalize;
`;

export const IconContainer = styled(BaseContainer)``;
