import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import UserAuthorization from "../../../authorization/UserAuthorization";

import { retrieveAreas } from "../../../api/calls/areaCall";
import {
  retrieveAreasErr,
  retrieveAreasSucc,
} from "../../../api/responses/areaResponse";

import Layout from "../../../utils/Layout";
import LoadingSpinner from "../../../utils/LoadingSpinner";
import InfoModal from "../../../components/modal/InfoModal";
import ErrorModal from "../../../components/modal/ErrorModal";

import {
  AreaPageContainer,
  SurveyTitle,
  AreaTableContainer,
  AreaTable,
  AreaTableBody,
  AreaTableRow,
  AreaTableHeader,
  AreaTableCell,
  AreaDateCell,
  AreaButtonRow,
  CreateAreaButton,
  CreateAreaStyledLink,
} from "../../../styles/page/areaComponents";

export default function AreaOversight() {
  const navigate = useNavigate();
  const [areas, setAreas] = useState([]);

  const [showInfoModal, setShowInfoModal] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!UserAuthorization.isAuthenticated()) {
      navigate("/welcome");
    } else {
      fetchAreas();
    }
  }, [navigate]);

  const fetchAreas = () => {
    setIsLoading(true); // Set loading to true when the request starts
    retrieveAreas()
      .then((response) => {
        retrieveAreasSucc(response, setAreas);
      })
      .catch((error) => {
        retrieveAreasErr(error, setErrorMessage, setIsErrorModalOpen);
      })
      .finally(() => {
        setIsLoading(false); // Set loading to false when the request completes
      });
  };

  const handleButtonClick = (area) => {
    switch (area.job.state) {
      case "completed":
        navigate(`/areas/${area.id}`);
        break;
      case "in-progress":
        setInfoMessage(
          "Το πάρκο δεν μπορεί να επεξεργαστεί. Η τρισδιάστατη ανακατασκευή είναι ακόμη σε εξέλιξη."
        );
        setShowInfoModal(true);
        break;
      case "failed":
        setInfoMessage(
          "Το πάρκο δεν μπορεί να επεξεργαστεί λόγω αποτυχίας της τρισδιάστατης ανακατασκευής."
        );
        setShowInfoModal(true);
        break;
      case "pending":
        setInfoMessage(
          "Το πάρκο δεν μπορεί να επεξεργαστεί. Η τρισδιάστατη ανακατασκευή είναι ακόμη σε αναμονή."
        );
        setShowInfoModal(true);
        break;
      default:
        break;
    }
  };

  const jobStatusMapping = {
    pending: "Σε αναμονή",
    "in-progress": "Σε εξέλιξη",
    completed: "Ολοκληρώθηκε",
    failed: "Απέτυχε",
  };

  if (isLoading) {
    // Display loading spinner while fetching data
    return (
      <AreaPageContainer>
        <LoadingSpinner />
      </AreaPageContainer>
    );
  }

  const sortedAreas = Array.isArray(areas)
  ? areas.slice().sort((a, b) => a.id - b.id)
  : [];

  return (
    <Layout>
      <InfoModal
        className="modal"
        isOpen={showInfoModal}
        onRequestClose={() => setShowInfoModal(false)}
        infoMessage={infoMessage}
      />
      <ErrorModal
        className="modal"
        isOpen={isErrorModalOpen}
        onRequestClose={() => setIsErrorModalOpen(false)}
        errorMessage={errorMessage}
      />
      <AreaPageContainer>
        <SurveyTitle>Προκαταρκτική Μελέτη</SurveyTitle>
        <AreaTableContainer>
          {sortedAreas.length > 0 ? (
            <AreaTable>
              <AreaTableBody>
                <AreaTableRow>
                  <AreaTableHeader>Κωδικός</AreaTableHeader>
                  <AreaTableHeader>Όνομα</AreaTableHeader>
                  <AreaTableHeader>Δημιουργία</AreaTableHeader>
                  <AreaTableHeader>Κατάσταση</AreaTableHeader>
                  <AreaTableHeader>Διαχειριστής</AreaTableHeader>
                </AreaTableRow>
                {sortedAreas.map((area) => (
                  <AreaButtonRow
                    key={area.id}
                    onClick={() => handleButtonClick(area)}
                  >
                    <AreaTableCell>{area.id}</AreaTableCell>
                    <AreaTableCell>{area.name}</AreaTableCell>
                    <AreaDateCell>
                      {new Date(area.created_at).toLocaleDateString("el-GR", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      })}
                    </AreaDateCell>
                    <AreaTableCell>
                      {jobStatusMapping[area.job.state]}
                    </AreaTableCell>
                    <AreaTableCell>{area.owner.username}</AreaTableCell>
                  </AreaButtonRow>
                ))}
              </AreaTableBody>
            </AreaTable>
          ) : (
            <div
              style={{ boxShadow: "rgba(0, 0, 0, 0.25) 0px 25px 50px -12px" }}
            >
              <br />
              <br />
              <p>Δεν βρέθηκαν οικόπεδα.</p>
              <br />
              <br />
            </div>
          )}
        </AreaTableContainer>
        <CreateAreaStyledLink to="/create-area">
          <CreateAreaButton>Δημιουργια Οικοπεδου</CreateAreaButton>
        </CreateAreaStyledLink>
      </AreaPageContainer>
    </Layout>
  );
}
