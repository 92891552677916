import UserAuthorization from "../../authorization/UserAuthorization";
import { handleApiError } from "../apiErrorHandling";

/**
 * Handles a successful user login.
 * @param {Object} response - The Axios response object.
 * @param {Function} navigate - Function to navigate to a different route/page.
 */
export const loginSucc = (response, navigate) => {
  if (response.status === 201) {
    // If login is successful, store the token and navigate to the home page
    UserAuthorization.login(response, () => {
      navigate("/");
    });
  }
};

/**
 * Handles an error during user login.
 * @param {Object} error - The Axios error object.
 * @param {Function} setLoginErrMsg - Function to set an error message related to login.
 * @param {Function} setIsErrorModalOpen - Function to control the error modal's visibility.
 */
export const loginErr = (error, setLoginErrMsg, setIsErrorModalOpen) => {
  handleApiError(error, setLoginErrMsg, setIsErrorModalOpen);
};
