import styled from "styled-components";

import {
  PageContainer,
  BaseTitle,
  BaseParagraph
} from "../sharedStyles";

export const EmailBody = styled(PageContainer)`
  height: 100vh;
  margin: -20px 0 50px;
`;

export const EmailContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  width: 800px;
  max-width: 100%;
  min-height: 500px;
`;

export const EmailTitle = styled(BaseTitle)`
  position: absolute;
  left: 0;
  right: 0;
  display: center;
  font-size: 20px;
  margin-top: 200px;
  margin-right: 90px;
  margin-left: 90px;
`;

export const Paragraph = styled(BaseParagraph)`
  position: absolute;
  left: 0;
  right: 0;
  display: center;
  color: #333333;
  font-size: 13px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.2px;
  margin-top: 300px;
  margin-bottom: 50px;
  margin-right: 90px;
  margin-left: 90px;
  top: 50;
`;

export const EmailRowContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 90px;
`;

export const Avatar = styled.div`
  display: grid;
  place-items: center;
  width: 85px;
  height: 85px;
  border-radius: 20px;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
`;
