/**
 * Handles a successful email verification response.
 * @param {Object} response - The Axios response object.
 * @param {Function} setMessage - Function to set a message related to email verification status.
 */
export const emailVerificationSucc = (response, setMessage) => {
  if (response.status === 204) {
    // Set the message to display verification success
    setMessage("verification done");
  }
};

/**
 * Handles an error during email verification.
 * @param {Object} error - The Axios error object.
 * @param {Function} setMessage - Function to set a message related to email verification status.
 */
export const emailVerificationErr = (error, setMessage) => {
  if (error.response) {
    if (error.response.status >= 400 && error.response.status <= 499) {
      // Set the message to display email already verified
      setMessage("email already verified");
    } else {
      // Set the message to display verification failed
      setMessage("verification failed");
    }
  } else {
    console.log(error);
  }
};
