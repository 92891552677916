import axios from "axios";

/**
 * Function to handle email verification.
 * @param {string} token - The verification token.
 * @returns {Promise} - Axios promise object representing the POST request.
 */
export const emailVerification = (token) => {
  // Configure headers for the request
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  // Make a POST request to the email verification endpoint
  return axios.post("email/verification", {}, config);
};