import styled from "styled-components";

import { BaseButton } from "../sharedStyles";

import cancelButton from "../../assets/cancel.png";

export const ImageModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* Ensure it's above other elements */
`;

export const ImageModalContent = styled.div`
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  max-width: 80vw; /* Limit the width of the modal */
  max-height: 80vh; /* Limit the height of the modal */
  overflow: auto; /* Add scrollbars if content overflows */
`;

export const ImageModalImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto; /* Ensure that the height adjusts to maintain aspect ratio */
  border-radius: 5px;
`;

export const ImageModalCloseButton = styled(BaseButton)`
  position: absolute;
  top: 20px;
  right: 40px;
  background-image: url(${cancelButton});
  background-size: cover;
  border: none;
  border-radius: 20px;
  width: 40px;
  height: 40px;
  transition: opacity 0.9s;

  &:hover {
    transform: scale(1.2);
  }
`;
