import { handleApiError } from "../apiErrorHandling";

/**
 * Handles a successful retrieval of inverters for a specific park from the API.
 * @param {Object} response - The Axios response object.
 * @param {string} parkId - The ID of the park for which the inverters are retrieved.
 * @param {Function} setInverters - Function to set the retrieved inverters in the component state.
 */
export const retrieveInvertersSucc = (response, parkId, setInverters) => {
  if (response.status === 200) {
    const inverterData = response.data.map((item) => ({
      id: item.id,
      manufacturer: item.manufacturer,
      model: item.model,
      power: item.power,
      parkId: parkId,
    }));

    setInverters(inverterData);
  }
};

/**
 * Handles an error during the retrieval of inverters for a specific park from the API.
 * @param {Object} error - The Axios error object.
 * @param {Function} setErrorMessage - Function to set an error message in the component state.
 * @param {Function} setIsErrorModalOpen - Function to control the error modal's visibility.
 */
export const retrieveInvertersErr = (
  error,
  setErrorMessage,
  setIsErrorModalOpen
) => {
  handleApiError(error, setErrorMessage, setIsErrorModalOpen);
};

/**
 * Handles a successful retrieval of a specific inverter in a park from the API.
 * @param {Object} response - The Axios response object.
 * @param {Function} setInverter - Function to set the retrieved inverter data in the component state.
 */
export const retrieveInverterSucc = (response, setInverter) => {
  if (response.status === 200) {
    setInverter(response.data);
  }
};

/**
 * Handles an error during the retrieval of a specific inverter in a park from the API.
 * @param {Object} error - The Axios error object.
 * @param {Function} setErrorMessage - Function to set an error message in the component state.
 * @param {Function} setIsErrorModalOpen - Function to control the error modal's visibility.
 */
export const retrieveInverterErr = (
  error,
  setErrorMessage,
  setIsErrorModalOpen
) => {
  handleApiError(error, setErrorMessage, setIsErrorModalOpen);
};

/**
 * Handles a successful deletion of a specific inverter from the API.
 * @param {Object} response - The Axios response object.
 * @param {Function} navigate - Function to navigate to a different route/page.
 */
export const deleteInverterSucc = (response, navigate) => {
  // Successful deletion, you can perform any additional actions here if needed
  console.log("Inverter deleted successfully");
};

/**
 * Handles an error during the deletion of a specific inverter from the API.
 * @param {Object} error - The Axios error object.
 * @param {Function} setErrorMessage - Function to set an error message in the component state.
 * @param {Function} setIsErrorModalOpen - Function to control the error modal's visibility.
 */
export const deleteInverterErr = (
  error,
  setErrorMessage,
  setIsErrorModalOpen
) => {
  handleApiError(error, setErrorMessage, setIsErrorModalOpen);
};
