// pages/ParkOversight.js
import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import UserAuthorization from "../../../authorization/UserAuthorization";

import { retrieveParks } from "../../../api/calls/parkCall";
import {
  retrieveParksSucc,
  retrieveParksErr,
} from "../../../api/responses/parkResponse";

import Layout from "../../../utils/Layout";
import LoadingSpinner from "../../../utils/LoadingSpinner";
import ErrorModal from "../../../components/modal/ErrorModal";

import {
  ParkPageContainer,
  OversightTitle,
  CreateParkButton,
  ParkTableContainer,
  ParkTable,
  ParkTableBody,
  ParkTableRow,
  ParkTableHeader,
  ParkTableCell,
  ParkDateCell,
  ParkButtonRow,
  CreateParkStyledLink,
} from "../../../styles/page/parkComponents";

export default function ParkOversight() {
  const navigate = useNavigate();
  const [parks, setParks] = useState([]);

  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!UserAuthorization.isAuthenticated()) {
      navigate("/welcome");
    } else {
      fetchParks();
    }
  }, [navigate]);

  const fetchParks = () => {
    setIsLoading(true);
    retrieveParks()
      .then((response) => {
        retrieveParksSucc(response, setParks);
      })
      .catch((error) => {
        retrieveParksErr(error, setErrorMessage, setIsErrorModalOpen);
      })
      .finally(() => {
        setIsLoading(false); // Set loading to false when the request completes
      });
  };

  const sortedParks = parks.slice().sort((a, b) => a.id - b.id);

  if (isLoading) {
    // Display loading spinner while fetching data
    return (
      <ParkPageContainer>
        <LoadingSpinner />
      </ParkPageContainer>
    );
  }

  return (
    <Layout>
      <ErrorModal
        className="modal"
        isOpen={isErrorModalOpen}
        onRequestClose={() => setIsErrorModalOpen(false)}
        errorMessage={errorMessage}
      />
      <ParkPageContainer>
        <OversightTitle>Επιτήρηση ΦΒ Πάρκου</OversightTitle>
        <ParkTableContainer>
          {sortedParks.length > 0 ? (
            <ParkTable>
              <ParkTableBody>
                <ParkTableRow>
                  <ParkTableHeader>Κωδικός</ParkTableHeader>
                  <ParkTableHeader>Όνομα</ParkTableHeader>
                  <ParkTableHeader>Δημιουργία</ParkTableHeader>
                  <ParkTableHeader>Διαχειριστής</ParkTableHeader>
                </ParkTableRow>
                {sortedParks.map((park) => (
                  <ParkButtonRow
                    key={park.id}
                    onClick={() => navigate(`/parks/${park.id}`)}
                  >
                    <ParkTableCell>{park.id}</ParkTableCell>
                    <ParkTableCell>{park.name}</ParkTableCell>
                    <ParkDateCell>
                      {new Date(park.created_at).toLocaleDateString("el-GR", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      })}
                    </ParkDateCell>
                    <ParkTableCell>{park.owner.username}</ParkTableCell>
                  </ParkButtonRow>
                ))}
              </ParkTableBody>
            </ParkTable>
          ) : (
            <div>
              <br />
              <br />
              <p>Δεν βρέθηκαν ΦΒ πάρκα.</p>
              <br />
              <br />
            </div>
          )}
        </ParkTableContainer>
        <CreateParkStyledLink to="/create-park">
          <CreateParkButton>Δημιουργια Παρκου</CreateParkButton>
        </CreateParkStyledLink>
      </ParkPageContainer>
    </Layout>
  );
}
