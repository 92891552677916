import { React, useState } from "react";
import { useNavigate } from "react-router-dom";

import UserAuthorization from "../../../authorization/UserAuthorization";

import { retrieveParks, createPark } from "../../../api/calls/parkCall";
import {
  createParkSucc,
  createParkErr,
} from "../../../api/responses/parkResponse";

import Layout from "../../../utils/Layout";
import ErrorModal from "../../../components/modal/ErrorModal";

import {
  ParkPageContainer,
  CreateParkBody,
  CreateParkContainer,
  CreateParkTitle,
  CreateParkForm,
  CreateParkInput,
  CreateParkRadioContainer,
  CreateParkRadioInput,
  CreateParkRadioLabel,
  CreateParkButton
} from "../../../styles/page/parkComponents";
import LoadingSpinner from "../../../utils/LoadingSpinner";

/**
 * CreatePark component represents the create park page.
 * Renders a form to create a new park.
 * @returns {JS.Element} - Rendered component.
 */
export default function CreatePark() {
  // Hook for navigation
  const navigate = useNavigate();

  // State variables for park creation form
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [privacy, setPrivacy] = useState("public");
  const [selectedFile, setSelectedFile] = useState(null);

  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  /**
   * Event handler for privacy radio buttons.
   * Updates the privacy state when a radio button is selected.
   * @param {Object} event - The event object.
   */
  const handlePrivacyChange = (event) => {
    setPrivacy(event.target.value);
  };

  /**
   * Event handler for park creation form submission.
   * Calls the createPark API function to create a new park.
   * Navigates to the welcome page if the user is not authenticated.
   * @param {Object} e - The event object.
   */
  const handleCreatePark = (e) => {
    e.preventDefault();

    if (!UserAuthorization.isAuthenticated()) {
      navigate("/welcome");
    } else {
      // Check if the park name already exists
      checkParkNameExists(name)
        .then((isParkNameExists) => {
          if (isParkNameExists) {
            // Display an error message indicating that the name already exists
            setErrorMessage(
              "Το όνομα του πάρκου υπάρχει ήδη. Παρακαλώ, καταχωρήστε διαφορετικό όνομα για το πάρκο που θέλετε να δημιουργήσετε."
            );
            setIsErrorModalOpen(true);
          } else {
            setIsLoading(true);
            createPark(name, description, privacy, selectedFile)
              .then((response) => {
                createParkSucc(response, navigate);
              })
              .catch((error) => {
                createParkErr(error, setErrorMessage, setIsErrorModalOpen);
              })
              .finally(() => {
                setIsLoading(false); // Set loading to false when the request completes
              });
          }
        })
        .catch((error) => {
          console.error("Error checking park name:", error);
        });
    }
  };

  // Function to check if the park name already exists
  const checkParkNameExists = (parkName) => {
    return retrieveParks()
      .then((response) => {
        const existingParks = response.data;
        // Check if the park name exists in the existing parks
        const existingPark = existingParks.find(
          (park) => park.name === parkName
        );

        return existingPark !== undefined;
      })
      .catch((error) => {
        console.error("Error retrieving parks:", error);
        return false;
      });
  };

  /**
   * Event handler for file input change.
   * Updates the selectedFile state when a file is selected.
   * @param {Object} event - The event object.
   */
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  if (isLoading) {
    // Display loading spinner while fetching data
    return (
      <ParkPageContainer>
        <LoadingSpinner />
      </ParkPageContainer>
    );
  }

  return (
    <Layout>
      {" "}
      <CreateParkBody>
        {" "}
        <CreateParkContainer>
          <ErrorModal
            className="modal"
            isOpen={isErrorModalOpen}
            onRequestClose={() => setIsErrorModalOpen(false)}
            errorMessage={errorMessage}
          />
          {/* Title */}
          <CreateParkTitle>Δημιουργία ΦΒ Πάρκου</CreateParkTitle>
          {/* Park creation form */}
          <CreateParkForm onSubmit={handleCreatePark}>
            {/* <Label>Όνομα</Label> */}
            <CreateParkInput
              id="parkName"
              name="parkName"
              type="text"
              placeholder="Όνομα"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />

            {/* <Label>Περιγραφή</Label> */}
            <CreateParkInput
              id="parkDescription"
              name="parkDescription"
              type="text"
              placeholder="Περιγραφή"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />

            <CreateParkInput
              id="excelFile"
              name="excelFile"
              type="file"
              onChange={handleFileChange}
              accept=".csv, .xls, .xlsx, .xlsm, .xlsb, .odf, .ods, .odt,"
            />

            {/* Radio buttons for privacy */}
            <CreateParkRadioContainer>
              <CreateParkRadioInput
                type="radio"
                id="public"
                name="privacy"
                value="public"
                checked={privacy === "public"}
                onChange={handlePrivacyChange}
              />
              <CreateParkRadioLabel>Δημόσιο</CreateParkRadioLabel>

              <CreateParkRadioInput
                type="radio"
                id="private"
                name="privacy"
                value="private"
                checked={privacy === "private"}
                onChange={handlePrivacyChange}
              />
              <CreateParkRadioLabel>Ιδιωτικό</CreateParkRadioLabel>
            </CreateParkRadioContainer>

            <CreateParkButton>Δημιουργία</CreateParkButton>
          </CreateParkForm>
        </CreateParkContainer>
      </CreateParkBody>
    </Layout>
  );
}
