import { handleApiError } from "../apiErrorHandling";

/**
 * Handles a successful retrieval of the current user information from the API.
 * @param {Object} response - The Axios response object.
 * @returns {boolean} - Returns true if the user is active, false otherwise.
 */
export const currUserSucc = (response) => {
  if (response.status === 200) {
    if (response.data.is_active) {
      return true;
    }
    return false;
  }
};

/**
 * Handles an error during the retrieval of the current user information from the API.
 * @param {Object} error - The Axios error object.
 * @returns {boolean} - Returns false to indicate an error occurred.
 */
export const currUserErr = (error) => {
  return false;
};

/**
 * Handles a successful user registration.
 * @param {Object} response - The Axios response object.
 * @param {Function} navigate - Function to navigate to a different route/page.
 * @param {string} email - The email used for registration.
 */
export const registerSucc = (response, navigate, email) => {
  if (response.status === 201) {
    navigate("/confirm-email", { state: { email: email } });
  }
};

/**
 * Handles an error during user registration.
 * @param {Object} error - The Axios error object.
 * @param {Function} setRegisterErrMsg - Function to set an error message related to registration.
 * @param {Function} setIsErrorModalOpen - Function to control the error modal's visibility.
 */
export const registerErr = (error, setRegisterErrMsg, setIsErrorModalOpen) => {
  handleApiError(error, setRegisterErrMsg, setIsErrorModalOpen);
};

/**
 * Function to handle errors when searching for users.
 *
 * @param {Object} error - The error object, typically from an HTTP request.
 * @param {Function} setErrorMessage - A function to set an error message in the UI.
 * @param {Function} setIsErrorModalOpen - A function to control the visibility of an error modal.
 */
export const searchUsersErr = (error, setErrorMessage, setIsErrorModalOpen) => {
  handleApiError(error, setErrorMessage, setIsErrorModalOpen);
};
