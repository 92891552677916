import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import UserAuthorization from "../../../authorization/UserAuthorization";

import {
  retrieveParks,
  retrievePark,
  updatePark,
  deletePark,
} from "../../../api/calls/parkCall";
import { retrieveInverters } from "../../../api/calls/inverterCall";
import { retrieveThermographies } from "../../../api/calls/thermographyCall";
import {
  retrieveParkSucc,
  retrieveParkErr,
  updateParkSucc,
  updateParkError,
  deleteParkSucc,
  deleteParkErr,
} from "../../../api/responses/parkResponse";
import {
  retrieveInvertersSucc,
  retrieveInvertersErr,
} from "../../../api/responses/inverterResponse";
import {
  retrieveThermographiesSucc,
  retrieveThermographiesErr,
} from "../../../api/responses/thermographyResponse";

import { ParkDetailsTabContent } from "./park_tabs/ParkDetailsTabContent";
import { ThermographicEventsTabContent } from "./park_tabs/ThermographicEventsTabContent";

import Layout from "../../../utils/Layout";
import LoadingSpinner from "../../../utils/LoadingSpinner";
import Tabs from "../../../utils/Tabs";
import ErrorModal from "../../../components/modal/ErrorModal";
import ConfirmationModal from "../../../components/modal/ConfirmationModal";
import SearchUsers from "../../../components/search/SearchUsers";

import { ParkPageContainer, ParkTitle } from "../../../styles/page/parkComponents";


export default function ParkDetails() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [park, setPark] = useState({});
  const [inverters, setInverters] = useState([]);
  const [thermographies, setThermographies] = useState([]);
  const [is_private, setPrivate] = useState(park.is_private || {});
  const [isLoading, setIsLoading] = useState(false);

  const [fetched, setFetched] = useState(false);

  const [showModal, setShowModal] = useState(false); // State for showing/hiding the modal
  const message = "Είστε σίγουροι ότι θέλετε να διαγράψετε το πάρκο;";

  const [isEditing, setIsEditing] = useState(false); // State for showing/hiding the edit form
  const [editedPark, setEditedPark] = useState({
    name: "",
    description: "",
    is_private: null,
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  useEffect(() => {
    if (!UserAuthorization.isAuthenticated()) {
      navigate("/welcome");
    } else {
      if (!fetched) {
        fetchParkDetails(id);
        fetchParkInverters(id);
        setFetched(true);
        fetchThermographies(id);
      }
    }
  }, [id, navigate, fetched]);

  const fetchParkDetails = (parkId) => {
    setIsLoading(true);
    retrievePark(parkId)
      .then((response) => {
        retrieveParkSucc(response, setPark, setPrivate); // Pass setPrivate as a parameter
      })
      .catch((error) => {
        retrieveParkErr(error, setErrorMessage, setIsErrorModalOpen);
      })
      .finally(() => {
        setIsLoading(false); // Set loading to false when the request completes
      });
  };

  const fetchParkInverters = (parkId) => {
    setIsLoading(true);
    retrieveInverters(parkId)
      .then((response) => {
        retrieveInvertersSucc(response, parkId, setInverters);
      })
      .catch((error) => {
        retrieveInvertersErr(error, setErrorMessage, setIsErrorModalOpen);
      })
      .finally(() => {
        setIsLoading(false); // Set loading to false when the request completes
      });
  };

  const handleDeletePark = (e) => {
    e.preventDefault();
    // Show the confirmation modal when the user clicks the delete button
    setShowModal(true);
  };

  const handleConfirmDelete = () => {
    // User confirmed deletion, perform the delete action here
    deletePark(id)
      .then((response) => {
        deleteParkSucc(response, navigate);
      })
      .catch((error) => {
        deleteParkErr(error, setErrorMessage, setIsErrorModalOpen);
      });

    // Hide the modal after deletion is complete
    setShowModal(false);
  };

  const handleCancelDelete = () => {
    // User canceled deletion, hide the modal
    setShowModal(false);
  };

  const handleEditPark = () => {
    setIsEditing(true);
    // Pre-fill the form fields with the current park data
    setEditedPark({
      name: park.name,
      description: park.description,
      is_private: park.is_private,
    });
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
  };

  const handleSaveEdit = () => {
    const { name, description } = editedPark;

    // Check if the name exists only when the user is editing the name
    if (name !== park.name) {
      checkParkFieldExists(id, "name", name)
        .then((isNameExists) => {
          if (isNameExists) {
            // Display an error message indicating that the name already exists
            setErrorMessage(
              "Το όνομα του πάρκου υπάρχει ήδη. Παρακαλώ, καταχωρήστε διαφορετικό όνομα."
            );
            setIsErrorModalOpen(true);
          } else {
            const updatedParkData = {
              name,
              description,
              is_private,
            };

            updatePark(id, updatedParkData)
              .then((response) => {
                updateParkSucc(response, updatedParkData, setPark);
              })
              .catch((error) => {
                updateParkError(error, setErrorMessage, setIsErrorModalOpen);
              });
          }
        })
        .catch((error) => {
          console.error("Error checking park name:", error);
        });
    } else {
      // If the user is not editing the name, proceed with updating other fields
      // Send a PATCH request to update the park data
      const updatedParkData = {
        name,
        description,
        is_private,
      };

      updatePark(id, updatedParkData)
        .then((response) => {
          updateParkSucc(response, updatedParkData, setPark);
        })
        .catch((error) => {
          updateParkError(error, setErrorMessage, setIsErrorModalOpen);
        });
    }
    // Exit edit mode
    setIsEditing(false);
  };

  const checkParkFieldExists = (parkId, field, value) => {
    return retrieveParks()
      .then((response) => {
        const existingParks = response.data;
        // Check if the specified field value exists in the existing parks
        const exists = existingParks.some((park) => park[field] === value);
        return exists;
      })
      .catch((error) => {
        console.error("Error retrieving parks:", error);
        return false;
      });
  };

  if (!park || !inverters) {
    return null;
  }

  const fetchThermographies = (parkId) => {
    setIsLoading(true);
    retrieveThermographies(parkId)
      .then((response) => {
        retrieveThermographiesSucc(response, setThermographies);
      })
      .catch((error) => {
        retrieveThermographiesErr(error, setErrorMessage, setIsErrorModalOpen);
      })
      .finally(() => {
        setIsLoading(false); // Set loading to false when the request completes
      });
  };

  if (isLoading) {
    // Display loading spinner while fetching data
    return (
      <ParkPageContainer>
        <LoadingSpinner />
      </ParkPageContainer>
    );
  }

  return (
    <Layout>
      <ConfirmationModal
        isOpen={showModal}
        onCancel={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        message={message}
      />

      <ErrorModal
        className="modal"
        isOpen={isErrorModalOpen}
        onRequestClose={() => setIsErrorModalOpen(false)}
        errorMessage={errorMessage}
      />
      <ParkPageContainer>
        <ParkTitle>{park.name}</ParkTitle>

        <SearchUsers placeId={park.id} />

        <Tabs>
          <ParkDetailsTabContent
            title="Γενικά"
            park={park}
            inverters={inverters}
            isEditing={isEditing}
            editedPark={editedPark}
            setEditedPark={setEditedPark}
            is_private={is_private}
            setPrivate={setPrivate}
            handleSaveEdit={handleSaveEdit}
            handleCancelEdit={handleCancelEdit}
            handleEditPark={handleEditPark}
            handleDeletePark={handleDeletePark}
          />
          <ThermographicEventsTabContent
            title="Θερμογραφία"
            thermographies={thermographies}
            navigate={navigate}
            id={id}
          />
        </Tabs>
      </ParkPageContainer>
    </Layout>
  );
}
