import styled from "styled-components";
import { Link } from "react-router-dom";

import {
  PageContainer,
  CreatePlaceBody,
  CreatePlaceContainer,
  CreatePlaceTitle,
  CreatePlaceForm,
  CreatePlaceInput,
  PlacesInfoRadioContainer,
  PlacesInfoRadioInput,
  PlacesInfoRadioLabel,
  EditPlacesInfoRadioContainer,
  EditPlacesInfoRadioInput,
  EditPlacesInfoRadioLabel,
  CreatePlaceButton,
  PlaceTableContainer,
  BaseTableContainer,
  PlaceTable,
  BaseTableBody,
  PlaceTableRow,
  BaseTableHeader,
  PlaceTableHeader,
  BaseTableCell,
  PlaceTableCell,
  PlaceDateCell,
  PlaceButtonRow,
  PlaceInfoContainer,
  PlaceInfoBackground,
  PlaceInfoWrapper,
  PlaceInfoID,
  PlaceInfoDescription,
  ModeTitle,
  BaseTitle,
  DownloadFilesButton,
  DownloadFilesImageButton,
  BaseCheckboxLabel,
  BaseInput,
  PlaceButtonsContainer,
  PlaceEditButtonContainer,
  PlaceDeleteButtonContainer,
  PlaceDeleteConfirmButton,
  PlaceDeleteCancelButton,
} from "../sharedStyles";

export const SurveyTitle = styled(ModeTitle)``;

export const AreaPageContainer = styled(PageContainer)`
  margin-top: 50px;
`;

export const CreateAreaBody = styled(CreatePlaceBody)``;

export const CreateAreaContainer = styled(CreatePlaceContainer)``;

export const CreateAreaTitle = styled(CreatePlaceTitle)``;

export const CreateAreaForm = styled(CreatePlaceForm)``;

export const CreateAreaInput = styled(CreatePlaceInput)``;

export const CreateAreaRadioContainer = styled(PlacesInfoRadioContainer)``;

export const CreateAreaRadioInput = styled(PlacesInfoRadioInput)``;

export const CreateAreaRadioLabel = styled(PlacesInfoRadioLabel)``;

export const EditAreaRadioContainer = styled(EditPlacesInfoRadioContainer)``;

export const EditAreaRadioInput = styled(EditPlacesInfoRadioInput)``;

export const EditAreaRadioLabel = styled(EditPlacesInfoRadioLabel)``;

export const CreateAreaButton = styled(CreatePlaceButton)``;

export const CreateAreaStyledLink = styled(Link)`
  color: #274a1c;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const AreaDetailsTitle = styled(BaseTitle)`
  margin: 40px 0;
`;

export const AreaTableContainer = styled(PlaceTableContainer)`
  max-width: 1600px;
`;

export const AreaTable = styled(PlaceTable)`
  border-collapse: collapse;
`;

export const AreaTableBody = styled(BaseTableBody)``;

export const AreaTableRow = styled(PlaceTableRow)``;

export const AreaTableHeader = styled(PlaceTableHeader)``;

export const AreaTableCell = styled(PlaceTableCell)``;

export const AreaDateCell = styled(PlaceDateCell)``;

export const AreaButtonRow = styled(PlaceButtonRow)``;

export const AreaDetailsTableContainer = styled(AreaTableContainer)`
  margin-top: 1%;
  margin-bottom: 1%;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
`;

export const AreaDetailsTable = styled(PlaceTable)`
  padding: 10px;
`;

export const AreaDetailsTableRow = styled(AreaTableRow)`
  &:nth-child(even) {
    background-color: #fff;
  }
`;

export const AreaDetailsTableBody = styled(BaseTableBody)``;

export const AreaDetailsTableHeader = styled(BaseTableHeader)`
  background: #b8d669;
  font-size: 20px;
  padding: 10px;
  border-radius: 5px 5px 0 0;
  height: 50px;
`;

export const AreaDetailsTableCell = styled(BaseTableCell)`
  padding: 10px;
  position: relative;
  border-radius: 0 0 5px 5px;

  /* Set fixed width for each cell */
  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(4) {
    width: 25%;
  }
`;

export const AreaDetailsDownloadButton = styled(DownloadFilesButton)``;

export const AreaDetailsDownloadImg = styled(DownloadFilesImageButton)``;

export const AreaInfoBackground = styled(PlaceInfoBackground)``;

export const AreaInfoWrapper = styled(PlaceInfoWrapper)``;

export const AreaInfoContainer = styled(PlaceInfoContainer)``;

export const AreaInfoRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export const AreaInfoID = styled(PlaceInfoID)``;

export const AreaInfoDescription = styled(PlaceInfoDescription)`
  width: 95%;
  max-width: 95%;
`;

export const AreaInfoCheckboxContainer = styled.div`
  display: block; /* Display each checkbox container as a block, stacking them vertically */
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 20px;
`;

export const AreaInfoCheckboxLabel = styled(BaseCheckboxLabel)`
  margin-left: 40px;
  margin-right: 40px;
`;

export const BaseInputCheckbox = styled.input`
  margin-right: 5px;
  width: 18px;
  height: 18px;
  margin: 5px;
`;

export const AreaInfoStyledCheckbox = styled(BaseInputCheckbox)``;

export const EditAreaInfoStyledCheckbox = styled(BaseInputCheckbox)`
  display: block;
`;

export const EditAreaInfoCheckboxLabel = styled(BaseCheckboxLabel)`
  margin-left: 10px;
  margin-left: 75px;
`;

export const EditAreaInfoInput = styled(BaseInput)`
  margin: 8px;
`;

export const AreaInfoButtonsContainer = styled(PlaceButtonsContainer)``;

export const EditAreaInfoButtonsContainer = styled(PlaceButtonsContainer)``;

export const EditAreaInfoButtonContainer = styled(PlaceEditButtonContainer)``;

export const AreaInfoDeleteButtonContainer = styled(
  PlaceDeleteButtonContainer
)``;

export const AreaInfoDeleteConfirmButton = styled(PlaceDeleteConfirmButton)``;

export const AreaInfoDeleteCancelButton = styled(PlaceDeleteCancelButton)``;
